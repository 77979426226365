import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ProviderJournalPage } from './class.page';
import { ListItemModal } from './list.item.modal';
import { RouterModule, Routes } from '@angular/router';
import { Globals } from '../../../globals';
import { File } from '@ionic-native/File/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { OrderModule } from 'ngx-order-pipe';
import { ProviderListPage } from './list.page';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {ProviderJournalRoutingModule} from './list.routing';
import {NgxImageCompressService} from 'ngx-image-compress';
import { VideoEditor } from '@ionic-native/video-editor/ngx';

import { JournalMenuModal } from './journal.menu.modal';
import { SortByPipe } from './sort.pipe';
import { GroupByPipe } from './list.group.pipe';
import {JournalFilterPipe } from './list.filter.pipe';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
    FormsModule,
    OrderModule,
    ProviderJournalRoutingModule,
    FontAwesomeModule,
  ],
  declarations: [
    ProviderJournalPage,
    ListItemModal,
    JournalMenuModal,
    ProviderListPage,
    GroupByPipe,
    JournalFilterPipe,
    SortByPipe
  ],
  entryComponents: [
   ListItemModal,
   ProviderListPage,
   JournalMenuModal
  ],
  providers: [
    Globals,
    File,
    FileTransfer,
    NgxImageCompressService,
    VideoEditor,
  ]
})
export class ProviderJournalModule {}
