export class User {
    id : string;
    role : string;
    deviceTokens : string[];
    lastLogin : Date;
    providerId : string;
    dontShowHelpFlag: boolean;

    constructor(params) {
        this.id = params.id;
        this.role = params.role;
        if (params.deviceTokens) this.deviceTokens = params.deviceTokens;
        if (params.lastLogin) this.lastLogin = params.lastLogin;
        if (params.providerId) this.providerId = params.providerId;
        if (params.dontShowHelpFlag) this.dontShowHelpFlag = params.dontShowHelpFlag;
    }
}