import { Component, NgZone, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Child, Family } from '../../../classes/child.class';
import { Classroom, Teacher } from '../../../classes/classroom.class';
import { Globals } from '../../../globals';
import * as firebase from 'firebase/app';
import { AuthService } from '../../../login/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-teacherclassroom',
  templateUrl: './teacherclassroom.page.html',
  styleUrls: ['./teacherclassroom.page.scss'],
})
export class TeacherclassroomPage implements OnInit {

  user : any;
  teacherDoc: any;
  teacher: any;
  classrooms: any[];
  providerId: string;
  allClassroomsMap: Map<any, any>;
  allStudentsMap: Map<any, any>;
  families: {};
  
  constructor( 
    private router: Router,
    private ngZone: NgZone,  
    private auth: AuthService, 
    private fireStore: AngularFirestore,
    ) {
        this.providerId = Globals.providerId;
        this.user = Globals.user;
        //console.log(this.providerId);
        this.getProvider();
  }

  async getProvider() {
    var db = firebase.firestore();
    var familyCollection = db.collectionGroup('families').where("providerId", "==", this.providerId);
    familyCollection.onSnapshot((querySnapshot) => {
      if (querySnapshot.size>0) {
        this.families = {};
        this.allStudentsMap = new Map();
      }
      querySnapshot.forEach((doc) => {
        var f = new Family(doc.data())
        f.id = doc.id;
        this.families[f.id] = f;        
        for (let [id,student] of Object.entries(f.children)) {
          this.allStudentsMap.set(id, new Child(student));
        }
      });
    });
    
    this.teacherDoc = this.fireStore.doc("providers/" + this.providerId).collection('teachers').doc(this.user.email);
    this.teacherDoc.valueChanges().subscribe(res => {
      //console.log(res);
      this.teacher =new Teacher(res);
      this.classrooms = [];
      res.classrooms.forEach(classroomref => {
        //console.log(classroomref);
        classroomref.get().then ( classroomdoc => {
            var classroom = new Classroom(classroomdoc.data());
            this.classrooms.push(classroom);
        })
      });
      
    });
  }

  ngOnInit() {
  }

  async checkIn(classroom) {
    this.teacherDoc.update(
      {
        checkedInClassroom : classroom.classname
      }
    );
    this.ngZone.run(() => this.router.navigate(['tabs/commercial-teacherjournal']));
  }

  async checkOut(classroom) {
    this.teacherDoc.update(
      {
        checkedInClassroom : null
      }
    );
   // this.ngZone.run(() => this.router.navigate(['tabs/commercial-teacherjournal']));
  }


  onSignOut() {
    if(Globals.token) {
      var userDoc = this.fireStore.doc("users/" + Globals.user.email);
      userDoc.set({
        deviceTokens: firebase.firestore.FieldValue.arrayRemove(Globals.token)
      }, { merge: true });  
    }
    Globals.user = "";
    Globals.providerole = false;
    this.auth.logout();
  }
}
