import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Classroom, Teacher } from '../../../classes/classroom.class';
import * as firebase from 'firebase/app';
import { Globals } from '../../../globals';

@Component({
  selector: 'classroom-student-modal',
  templateUrl: 'classroom.student.modal.html',
  styleUrls: ['classroom.page.scss']
})
export class ClassroomStudentModal implements OnInit {
  editItem: any;
  user: string;
  mode: string;
  classroom: Classroom;
  classroomCollection: any;
  studentsCollection: any;
  allStudentsMap: Map<string, Teacher>;

  toAdd: Set<string>;
  toRemove: Set<string>;

  constructor(private modalController: ModalController) { }

  ngOnInit() {
    this.toAdd = new Set<string>();
    this.toRemove = new Set<string>();    
  }

  cancel() {
    this.modalController.dismiss()
  }

  /**
   * Captures and console logs the value emitted from the user depressing the accordion component's <ion-button> element 
   * @public
   * @method captureName
   * @param {any}		event 				The captured event
   * @returns {none}
   */
  public captureName(event: any) : void
  {
     console.log(`Captured name by event value: ${event}`);
  }

  selectionChanged($event, studentId) {
    if($event.target.checked == true) {
      this.toRemove.delete(studentId);
      this.toAdd.add(studentId);
    } else {
      this.toAdd.delete(studentId);
      this.toRemove.add(studentId);
    }
  }

  async saveStudents() {
    let  classroomDoc  = this.classroomCollection.doc(this.classroom.classname);
    // handle additions
    if(this.toAdd.size > 0) {
      let tempArr = [];
      this.toAdd.forEach(element => {        
        tempArr.push(element);
      });
      classroomDoc.update({
        students: firebase.firestore.FieldValue.arrayUnion(...tempArr)
      });
    }
    // handle removals
    if(this.toRemove.size > 0) {
      let tempArr = [];
      this.toRemove.forEach(element => {        
        tempArr.push(element);
      });
      classroomDoc.update({
        students: firebase.firestore.FieldValue.arrayRemove(...tempArr)
      });
    }    

    this.modalController.dismiss();
    Globals.presentToast("Save ... complete");
  }  
}