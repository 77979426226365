import { Component, OnInit } from '@angular/core';

//frameworks
import * as md5 from 'md5';
import * as moment from 'moment';

//Firebase
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController } from '@ionic/angular';
import { UserItemModal } from './user.item.modal';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.page.html',
  styleUrls: ['./admin.page.scss'],
})
export class AdminPage implements OnInit {

  users: any;
  filteredUsers: any;

  constructor(
    public modalController: ModalController,
    private fireStore: AngularFirestore,

  ) { }

  ngOnInit() {
    var userCollection = this.fireStore.collection('users').valueChanges({ idField: "id" }).subscribe((res) => {
      //console.log(res);
      this.users = res;
      this.filteredUsers = res;
    });
  }

  getMD5(name = "dummy") {
    return md5(name);
  }

  formatDate(date) {
    if (date) {
      return moment(date.toDate()).format("ddd DD, MMM YYYY hh:mm A").toString();
    }
  }

  filter(event) {
    const query = event.target.value.toLowerCase();
    this.filteredUsers = this.users.filter(item => {
      return item.id.toLowerCase().indexOf(query) > -1;
    });
  }

  async showModal(i) {
    let props = {
      item: this.filteredUsers[i]
    };

    // Create the modal
    const modal = await this.modalController.create({
      component: UserItemModal,
      componentProps: props,
    });
    return await modal.present()
  }

}
