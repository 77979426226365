import { Globals } from '../../../globals';

//ionic - angular - firebase
import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, Platform, ActionSheetController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';

//frameworks
import * as md5 from 'md5';
import * as moment from 'moment';

// pages
import { Filters } from '../../../classes/filter.class';
import { ListItemModal } from './list.item.modal';

// entities
import { Journal, JournalMap, JournalType } from '../../../classes/journal.class';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-list-page',
  templateUrl: 'list.page.html',
  styleUrls: ['class.page.scss']
})
export class ProviderListPage implements OnInit {

  //props
  child: any;
  parentEmail: any;

  modal: any;
  data: any;
  user: any;

  showFilter: boolean;
  filters: Filters;
  platform: any;


  journalArray: any;

  globals: Globals;
  journalIcons: JournalType;
  loading: boolean;
  journalSize: number;
  filterStartDate: any;

  db: firebase.firestore.Firestore;
  reactionIcons: any;

  constructor(
    public modalController: ModalController,
    private loadingController: LoadingController,
    public actionSheetController: ActionSheetController,
    private fireStore: AngularFirestore,
  ) {
    this.reactionIcons = {
      like : { iconImage: 'assets/img/like.png' },
      love : {iconImage: 'assets/img/love.png' },
      haha : { iconImage: 'assets/img/haha.png' },
      wow : { iconImage: 'assets/img/wow.png'},
      sad : { iconImage: 'assets/img/sad.png' },
      angry : { iconImage: 'assets/img/angry.png' }
    };
    
    this.journalIcons = new JournalType();
    this.filters = new Filters();
    this.filters.timeFilter = "All";
    this.loading = true;
    this.journalArray = [];
    this.db = firebase.firestore();
  }

  async ngOnInit() {

    //read journal doc
    this.platform = Capacitor.getPlatform();

    this.user = Globals.user;
    this.journalArray = [];

    this.filterStartDate = moment().subtract(14, "days").format("YYYYMMDDHHmmSSSS").toString();
    this.getData();
  }

  getData() {


    this.fireStore.collectionGroup('journalItem', ref => ref.where("parentId", "==", this.parentEmail)
      .where("childId", "==", this.child.id)
      .where("journalts", ">", this.filterStartDate)
      .orderBy("journalts", "desc"))
      .valueChanges().subscribe(res => {
        //console.log(res);
        this.journalArray = res;
        var i = 0;
        for (let item of this.journalArray) {
          item.index = i++
        }
        this.loading = false;
        this.journalSize = this.journalArray.length;
      });
  }

  async onMenu(childId, journalindex) {
    var item = this.journalArray[journalindex];

    const actionSheet = await this.actionSheetController.create({
      header: 'Journal Actions',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'Edit',
        icon: 'create-outline',
        handler: () => {
          this.modify(item, childId);
        }
      }, {
        text: 'Delete',
        icon: 'trash',
        role: "destructive",
        handler: () => {
          this.delete(item);
        }
      }, {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          //console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();
  }

  async modify(item, childId) {

    let props = {
      journal: item || new Journal({ userId: this.user.id }),
      editItem: item,
      childName: this.child.name
    };

    //console.log(props);

    // Create the modal
    this.modal = await this.modalController.create({
      component: ListItemModal,
      componentProps: props
    });

    // Listen for the modal to be closed...
    this.modal.onDidDismiss().then((result) => {
      // console.log(result);
      if (result.data) {
        if (result.data.newItem) {
          result.data.newItem.childId = childId;
          result.data.newItem.parentId = this.parentEmail;
          this.save(result.data.newItem);
        } else if (result.data.editItem) {
          this.save(result.data.editItem);
        }
      }
    })
    return this.modal.present()
  }

  async delete(journalEntry) {
    Globals.presentToast("Deleting ...");
    // console.log("Deleting :" + JSON.stringify(list));
    //console.log(journalEntry);

    var journalDoc = this.fireStore.doc("journal/" + this.parentEmail).collection("journalItem").doc(journalEntry.id);
    journalDoc.delete().then(function () {
      //console.log("Document successfully deleted!");
      Globals.presentToast("Delete ... complete");

    }).catch(function (error) {
      console.error("Error removing document: ", error);
    });
  }

  showDateBreak(i) {
    if (!i) return true;
    return !((this.journalArray[i].formattedJournalDate) == (this.journalArray[i - 1].formattedJournalDate));
  }

  formatDate(date) {
    return moment(date).format("ddd, MMM D");
  }

  async save(journalEntry: any) {
    Globals.presentToast("Saving ...");
    //console.log("Saving :" + JSON.stringify(journalEntry));
    var journalDoc = this.fireStore.doc("journal/" + this.parentEmail).collection("journalItem").doc(journalEntry.id);
    journalDoc.set(
      Object.assign({}, journalEntry),
      { merge: true }
    ).catch(function (error) {
      console.error("Error removing document: ", error);
    });

    Globals.presentToast("Save ... complete");
  }

  timefilter($event) {
    //console.log($event.target.value);
    this.filters.timeFilter = $event.target.value;

    if (this.filters.timeFilter === "Today") {
      this.filterStartDate = moment().subtract(1, "days").format("YYYYMMDDHHmmSSSS").toString();
    } else if (this.filters.timeFilter === "This Week") {
      this.filterStartDate = moment().subtract(7, "days").format("YYYYMMDDHHmmSSSS").toString();
    } else
      this.filterStartDate = moment().subtract(30, "days").format("YYYYMMDDHHmmSSSS").toString();

    //console.log("Filter Start:" + this.filterStartDate);
    this.getData();
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
    this.filters.filtersApplied = true;
  }

  closeModal() {
    this.modalController.dismiss();
  }
  getMD5(name = "dummy") {
    return md5(name);
  }
}

