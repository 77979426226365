import { Globals } from '../../../globals';

//angular - firebase 
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import * as firebase from 'firebase/app';

//ionic
import { ModalController, LoadingController, ToastController, ActionSheetController } from '@ionic/angular';

//frameworks
import * as md5 from 'md5';
import { v4 as uuid } from 'uuid';

//pages
import { Filters } from '../../../classes/filter.class';

//entities
import { Journal, JournalType } from '../../../classes/journal.class';
import { Child, Family } from '../../../classes/child.class';
import { User } from '../../../classes/user.class';
import { environment } from '../../../../environments/environment';


import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { ProviderJournalPage } from './journal.page';

@Component({
  selector: 'app-approval-page',
  templateUrl: 'approval.page.html',
  styleUrls: ['approval.page.scss']
})

export class ApprovalPage implements OnInit {

  faBullhorn = faBullhorn;

  user: any;

  showFilter: boolean;
  filters: Filters;

  journal: Journal | any;
  journalIcons: JournalType;
  menumodal: any;
  families: any;
  functions: any;
  sendNotification: any;
  broadcast: boolean;

  constructor(
    public modalController: ModalController,
    private loadingController: LoadingController,
    private actionSheetController: ActionSheetController,
    private fireStore: AngularFirestore,
    private fireFunctions: AngularFireFunctions
  ) {
    //todo: replace with firebase auth
    this.user = Globals.user;
    var id = this.user.email;
    

    //Families collection
    var db = firebase.firestore();

    var familyCollection = db.collectionGroup('families').where("providerId", "==", id);
    familyCollection.onSnapshot((querySnapshot) => {
      if (querySnapshot.size>0) this.families = {};
      querySnapshot.forEach((doc) => {
        var f = new Family(doc.data())
        f.id = doc.id;
        this.families[f.id] = f;
        for (var child in f.children) {
          this.getPendingApprovalCount(f.id, child);
        };
      });
    });    
  }

  getPendingApprovalCount(parentEmail, child) {
    //console.log(this.families[parentEmail].children[child]);
    this.fireStore.collectionGroup('journalItem', ref => ref.where("parentId", "==", parentEmail).where("approval","==", false)
      .where("childId", "==", child)
      .orderBy("journalts", "desc"))
      .valueChanges().subscribe(res => {
        //console.log(res);
        this.families[parentEmail].children[child].unapproved  = res.length;
      });
  }
  async ngOnInit() {
  }
  
  async viewItem(parentEmail: string, childItem: string) {

    //console.log(parentEmail);

    //console.log("view:"+this.families[parentEmail].children[childItem].childId);
    let props = {
      child: this.families[parentEmail].children[childItem],
      parentEmail: parentEmail
    };

    // Create the modal
    const modal = await this.modalController.create({
      component: ProviderJournalPage,
      componentProps: props,
      cssClass: "modal-fullscreen",
      animated : false
    });
    return await modal.present()
  }

  getMD5(name = "dummy") {
    return md5(name);
  }
}