import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.page.html',
  styleUrls: ['./landing.page.scss'],
})
export class LandingPage implements OnInit {

  constructor(
    public authService: AuthService,
    public router: Router
  ) {}

  ngOnInit() {
  }

  loginWithGoogle() {
   // this.authService.signInWithGoogle();
  }

  loginWithEmail() {
    this.router.navigate(['login/email']);
  }
  
  signup() {
    this.router.navigate(['login/register']);
  }

  signupProvider() {
    this.router.navigate(['login/provider']);
  }

}
