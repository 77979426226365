import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import * as firebase from 'firebase/app';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';

import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  Capacitor
} from '@capacitor/core';
import { Globals } from './globals';
import { Router } from '@angular/router';
const { SplashScreen } = Plugins;
const { PushNotifications } = Plugins;


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  registration: any;
  constructor(
    private platform: Platform,
    public alertController: AlertController,
    private router: Router,
    private afMessaging: AngularFireMessaging,
    private ga: GoogleAnalytics
  ) {
    this.initializeApp();
    
  }

  async initializeApp() {
    this.platform.ready().then(async () => {
      //StatusBar.styleDefault();
      SplashScreen.hide();
      this.ga.startTrackerWithId('G-UA-180201962-1')
      .then(() => {
        //console.log('Google analytics is ready now');
        this.ga.trackView('MyDayCareApp');
      })
      .catch(e => console.log('Error starting GoogleAnalytics', e));
    });
  }

  async ngAfterContentInit() {

  }


  ngOnInit() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    if (Capacitor.isPluginAvailable('PushNotifications')) {
      PushNotifications.requestPermission().then(result => {
        if (result.granted) {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          Globals.presentToast("Error: Notifications not enabled. Please grant permission to enable notifications");
        }
      });


      PushNotifications.addListener('registration',
        (token: PushNotificationToken) => {
          console.debug('Push registration success, token: ' + token.value);
          Globals.token = token.value;
        }
      );

      PushNotifications.addListener('registrationError',
        async (error: any) => {
          console.debug('Error on registration: ' + JSON.stringify(error));

          const alert = await this.alertController.create({
            header: "Error",
            message: "Registration for push notification failed. Please check application permissions.",
            buttons: ['Ok']
          });

          await alert.present();
        }
      );

      PushNotifications.addListener('pushNotificationReceived',
        async (notification: PushNotification) => {
          if (notification.data.type == "journal" && !Globals.providerole) {
            const alert = await this.alertController.create({
              header: notification.title,
              message: notification.body,
              buttons: [
                {
                  text: 'View',
                  handler: () => {
                    //window.location.href = './tabs/list';
                    //console.debug(notification.data);
                    this.router.navigateByUrl('tabs/list');
                    //angular router !!!
                  }
                }, {
                  text: 'Cancel',
                  role: 'cancel',
                }
              ]
            });
            await alert.present();
          }
          if (notification.data.type == "chat" && !Globals.chatPage) {
            return;

            const alert = await this.alertController.create({
              header: notification.title,
              message: notification.body,
              buttons: [
                {
                  text: 'View',
                  handler: () => {
                    //window.location.href = './tabs/list';
                    //console.debug(notification.data);
                    this.router.navigateByUrl('tabs/chat-list');
                    Globals.chatBadge = true;
                    //angular router !!!
                  }
                }, {
                  text: 'Cancel',
                  role: 'cancel',
                }
              ]
            });
            await alert.present();
          }


        }
      );

      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: PushNotificationActionPerformed) => {
          var type = notification.notification.data.type;
          PushNotifications.removeAllDeliveredNotifications();

          console.debug('Push action performed: ' + JSON.stringify(notification));
          //console.debug(notification.data);
          //window.location.href = './tabs/list';

          if (type == "journal" && !Globals.providerole) {
            this.router.navigateByUrl('tabs/list');
          } else if (type == "chat") {
            this.router.navigateByUrl('tabs/chat-list');
            Globals.chatBadge = true;
          } else {
            this.router.navigateByUrl('tabs/chat-list');
          }
        }
      );
    } else {
      //const messaging = firebase.messaging();
      this.afMessaging.usePublicVapidKey("BEWndxtFMUH-4FXmtN0vHQ5ErTgx93T_6YwgS8GoRFWkC8CxpdtM6dV58Pf6AQCbtzBWC-WRwB9SyRR0DNGiXuE");
      //console.log("hello...")
      this.afMessaging.requestToken.subscribe((currentToken) => {
        if (currentToken) {
          Globals.token = currentToken;
          //console.log('Token Generated ...',currentToken);
        } else {
          //console.log('No Instance ID token available. Request permission to generate one.');
          // Show permission UI. -- not reqiured for web -- handled through Ionic
        }
      }, ((error) => {
        console.log('An error occurred while retrieving token. ', error);
      }));
    }
  }
}
