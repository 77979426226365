import { Globals } from '../../../globals';

//angular - firebase 
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import * as firebase from 'firebase/app';

//ionic
import { ModalController, LoadingController, ToastController, ActionSheetController } from '@ionic/angular';

//frameworks
import * as md5 from 'md5';
import { v4 as uuid } from 'uuid';

//pages
import { ListItemModal } from './list.item.modal';
import { ProviderListPage } from './list.page';
import { Filters } from '../../../classes/filter.class';
import { JournalMenuModal } from './journal.menu.modal';

//entities
import { Journal, JournalType } from '../../../classes/journal.class';
import { Child, Family } from '../../../classes/child.class';
import { User } from '../../../classes/user.class';
import { environment } from '../../../../environments/environment';


import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { Classroom, Teacher } from '../../../classes/classroom.class';

@Component({
  selector: 'app-list-page',
  templateUrl: 'class.page.html',
  styleUrls: ['class.page.scss']
})

export class ProviderJournalPage implements OnInit {

  faBullhorn = faBullhorn;

  user: any;

  showFilter: boolean;
  filters: Filters;

  journal: Journal | any;
  journalIcons: JournalType;
  menumodal: any;
  families: any;
  allFamilies: any;
  functions: any;
  sendNotification: any;
  broadcast: boolean;

  classroomDoc: any;
  checkedInClassroom: any;
  studentToFamilyMap: Map<string, string>;
  teacherDoc: any;
  teacher: Teacher;

  constructor(
    public modalController: ModalController,
    private loadingController: LoadingController,
    private actionSheetController: ActionSheetController,
    private fireStore: AngularFirestore,
    private fireFunctions: AngularFireFunctions
  ) {
    this.user = Globals.user;
    this.functions = firebase.app().functions('us-central1');
    this.sendNotification = this.functions.httpsCallable('sendNotification');
    
    this.allFamilies = {};
  }

  async getData(){
    var db = firebase.firestore();
    //Families collection
    var familyCollection = db.collectionGroup('families').where("providerId", "==", Globals.providerId);
    familyCollection.onSnapshot((querySnapshot) => {
      //console.log(querySnapshot.size)
      if (querySnapshot.size>0) {
        this.families = {};
        this.studentToFamilyMap = new Map();
      }
      querySnapshot.forEach((doc) => {
        //console.log(doc.data());
        var f = new Family(doc.data())
        f.id = doc.id;
        this.allFamilies[f.id] = f;
        for (let [id,student] of Object.entries(f.children)) {
          this.studentToFamilyMap.set(id, f.id);
        }
      });
    });

    //Get students from checked-in classroom
    this.teacherDoc = this.fireStore.doc("providers/" + Globals.providerId).collection('teachers').doc(this.user.email);
    this.teacherDoc.valueChanges().subscribe(res => {
      this.teacher =new Teacher(res);
      this.families = {};
      //console.log(this.teacher);
      this.checkedInClassroom = null;
      this.classroomDoc = this.fireStore.doc("providers/" + Globals.providerId).collection('classroom').doc(this.teacher.checkedInClassroom);
      this.classroomDoc.valueChanges().subscribe(res => {
        this.checkedInClassroom = new Classroom(res);
        this.checkedInClassroom.students.forEach(student => {
          //console.log(student);
          let familyId = this.studentToFamilyMap.get(student);
          this.families[familyId] = this.allFamilies[familyId];        
        });      
      });
     // console.log(this.families);
      //console.log(this.allFamilies);
    }); 
  }
  async ngOnInit() {
    this.getData();
  }

  async ionViewDidEnter() {
    this.getData();
  }
  


  async showMenu(childId, parentEmail) {

    var absent = this.families[parentEmail].children[childId].absent || false;
    var checkedin = this.families[parentEmail].children[childId].checkedin || false;

    let props = {
      absent: absent,
      checkedin: checkedin,
      childname: this.families[parentEmail].children[childId].name
    };

    //console.log(JSON.stringify(props));

    // Create the modal
    const modal = await this.modalController.create({
      component: JournalMenuModal,
      componentProps: props,
      cssClass: "modal-fullscreen"
    });

    // Listen for the modal to be closed...
    modal.onDidDismiss().then((result) => {
      //console.log("Result:" + JSON.stringify(result.data));
      if (!result.data) return;

      const action = result.data.action;
      switch (action) {
        case "add": {
          this.modify(null, childId, parentEmail, result.data.type);
          break;
        }
        case "checkin": {
          this.updatecheckin(parentEmail, childId, result.data.checkedin);
          break;
        }
        case "absence": {
          this.updateabsent(parentEmail, childId, result.data.absent);
          break;
        }
        case "view": {
          this.viewItem(parentEmail, childId);
          break;
        }
      }

    });
    return modal.present();
  }


  updateabsent(parentEmail: string, childItem: string, absent: boolean) {

    //save - child state
    var child = this.families[parentEmail].children[childItem];
    child.absent = absent;

    var familyDoc = this.fireStore.doc("families/" + parentEmail);
    familyDoc.set({
      children: {
        [child.id]: Object.assign({}, child)
      }
    }, { merge: true });

    //add new journal    
    //console.log("checkin:"+parentEmail, childItem, this.families[parentEmail].children[childItem].checkedin);
    if (absent) {
      var journalEntry: Journal = new Journal({});
      journalEntry.title = "Absent";
      journalEntry.type = "Absence";
      journalEntry.childId = child.id;
      journalEntry.parentId = parentEmail;
      journalEntry.description = "Child is absent for today";
      this.createJournal(journalEntry, parentEmail);
    }
  }


  updatecheckin(parentEmail: string, childItem: string, checkin: boolean) {

    //save - child state
    var child = this.families[parentEmail].children[childItem];
    child.checkedin = checkin;
    if (checkin) child.absent = false;


    var familyDoc = this.fireStore.doc("families/" + parentEmail);
    familyDoc.set({
      children: {
        [child.id]: Object.assign({}, child)
      }
    }, { merge: true });

    //add new journal    
    var journalEntry: Journal = new Journal({});
    journalEntry.title = (checkin ? "Checked-In" : "Checked-Out");
    journalEntry.type = (checkin ? "Checkin" : "Checkout");
    journalEntry.childId = child.id;
    journalEntry.parentId = parentEmail;
    journalEntry.description = (checkin ? "Checked in" : "Checked out");
    this.createJournal(journalEntry, parentEmail);
  }

  async createJournal(journalEntry: Journal, parentEmail) {
    //console.log(journalEntry);

    Globals.presentToast("Saving ...");
    // These conditions require approval
    if(journalEntry.fileList != undefined && journalEntry.fileList.length > 0) journalEntry.approval = false;
    if(journalEntry.file != undefined) journalEntry.approval = false;
    if(journalEntry.videofile != undefined) journalEntry.approval = false;
    //save journal
    var journalDoc = this.fireStore.doc("journal/" + parentEmail).collection("journalItem").doc(journalEntry.id);
    journalDoc.set(
      Object.assign({}, journalEntry)
      , { merge: true });

    //send notification only if approval is not required
    if(journalEntry.approval) {
      this.sendUserNotification(parentEmail, "Your child has a new Journal Entry : " + journalEntry.description, "New Journal Entry", "journal");
    }
    Globals.presentToast("Save ... complete");
  }

  async viewItem(parentEmail: string, childItem: string) {

    //console.log(parentEmail);

    //console.log("view:"+this.families[parentEmail].children[childItem].childId);
    let props = {
      child: this.families[parentEmail].children[childItem],
      parentEmail: parentEmail
    };

    // Create the modal
    const modal = await this.modalController.create({
      component: ProviderListPage,
      componentProps: props,
      cssClass: "modal-fullscreen",
      animated : false
    });
    return await modal.present()
  }

  async modify(item, childId, parentEmail, journaltype = null, broadcastList = null) {

    var concatName = "";
    if (broadcastList) {
      for (let child of broadcastList) {
        concatName = concatName + child.name + ",";
      }
    }

    let props = {
      journal: this.journal || new Journal({
        userId: this.user.id,
      }),
      childName: broadcastList ? concatName : this.families[parentEmail].children[childId].name,
      journaltype: journaltype,
      editItem: item || undefined,
    };

    //console.log(props);

    // Create the modal
    const modal = await this.modalController.create({
      component: ListItemModal,
      componentProps: props,
      animated : false
    });
    
    // Listen for the modal to be closed...
    var response: any;
    modal.onDidDismiss().then((result) => {
      if (broadcastList) {
        for (let child of broadcastList) {
          var newJournal : Journal = new Journal (result.data.newItem);
          newJournal.id  = uuid();
          newJournal.childId = child.id;
          newJournal.parentId = child.email;
          this.createJournal(newJournal, child.email);
        }
      };
      if (!result.data) return;
      if (result.data.newItem) {
        result.data.newItem.childId = childId;
        result.data.newItem.parentId = parentEmail;
        //console.log(result.data.newItem);
        this.createJournal(result.data.newItem, parentEmail);
      } else if (result.data.editItem) {
        this.createJournal(result.data.editItem, parentEmail);
      }
    });

    return await modal.present();
  }

  getMD5(name = "dummy") {
    return md5(name);
  }

  async sendBroadcast() {
    if (!this.broadcast) this.broadcast = true;
    else {
      this.broadcast = false;

      let props = {
        absent: false,
        checkedin: false,
        childname: "Broadcast",
        broadcast: true
      };

      //console.log(JSON.stringify(props));

      // Create the modal
      const modal = await this.modalController.create({
        component: JournalMenuModal,
        componentProps: props,
        cssClass: "modal-fullscreen",
        animated : false
      });
      // Listen for the modal to be closed...
      modal.onDidDismiss().then(async (result) => {
        //console.log("Result:" + JSON.stringify(result.data));
        if (!result.data) return;

        const action = result.data.action;
        //if "add" show modal
        var broadcastList: Child[] = [];

        //loop selection 
        for (let [id, family] of Object.entries<Family>(this.families)) {
          for (let [id, child] of Object.entries<Child>(family.children)) {
            if (child.isChecked) {
              switch (action) {
                case "add": {
                  broadcastList.push(child);
                  break;
                }
                case "checkin": {
                  this.updatecheckin(family.id, child.id, result.data.checkedin);
                  break;
                }
                case "absence": {
                  this.updateabsent(family.id, child.id, result.data.absent);
                  break;
                }
                case "view": {
                  this.viewItem(family.id, child.id);
                  break;
                }
              }
            }
          }
        }
        //add journal for the list 
        if (action == "add") var response = await this.modify(null, "broadcast", "broadcast", result.data.type, broadcastList);
      });
      return modal.present();
    }
  }

  sendUserNotification(user, message, title, type) {
    var userDoc = this.fireStore.doc("users/" + user);
    userDoc.ref.get().then(doc => {
      //console.log(doc.data());
      if (!doc.data()) return;

      var userObj = new User(doc.data());
      var data = {
        user: user,
        message: message,
        title: title,
        deviceTokens: userObj.deviceTokens,
        data: {
          type: type
        }
      }
      //console.log(data);
      this.sendNotification(data).then((result) => {
        //console.log(result);
        result.data.result.results.forEach((result, index) => {
          if (result.error) {
            //console.log("invalid token:"+ userObj.deviceTokens[index]);
            userDoc.set({
              deviceTokens: firebase.firestore.FieldValue.arrayRemove(userObj.deviceTokens[index])
            }, { merge: true });
          }
        });
      }).catch((error) => {
        console.log(error);
      });
    });
  }
}