import { v4 as uuid } from 'uuid';
import { Family } from './child.class';

export class Provider {
    id : string;
    name : string;
    address : string;
    phone : string;
    profilePicture : string;
    reminders : any;
    email: any;

    constructor(params=null) {
        if(!params || !params.id) {
            this.id = uuid();
            //console.log(params);
            //throw ("Error: Cannot create provider without a valid id");
        } else {
            this.id=params.id;
            if(params.name) this.name= params.name;
            if(params.address) this.address = params.address;
            if(params.phone) this.phone = params.phone;
            if(params.profilePicture) this.profilePicture = params.profilePicture;
            if(params.reminders) this.reminders = params.reminders;
            if(params.email) this.email = params.email;
        }
    }
}