import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.page.html',
  styleUrls: ['./email.page.scss'],
})
export class EmailPage implements OnInit {

  password_type: string = 'password';


  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  logIn(email, password) {
    this.authService.signInWithEmail(email.value.trim().toLowerCase(), password.value);
  }

  signup() {
    this.router.navigate(['login/register']);
  }

  forgotPassword() {
    this.router.navigate(['login/forgot']);
  }

  togglePasswordMode() {
    this.password_type === 'text' ? 'password' : 'text';
  }
}
