import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ListPage } from './list.page';
import { Globals } from '../../../globals';
import { OrderModule } from 'ngx-order-pipe';
import { RouterModule } from '@angular/router';

import {JournalFilterPipe } from './list.filter.pipe';
import { LongPressDirective } from './long-press.directive';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
    FormsModule,
    OrderModule,
    RouterModule.forChild([{ path: '', component: ListPage }]),
  ],
  declarations: [
    ListPage,
    JournalFilterPipe,
    LongPressDirective
  ],
  entryComponents: [
    
  ],
  providers: [
    Globals,    
  ]
})
export class ListModule {}
