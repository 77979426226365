import { v4 as uuid } from 'uuid';
import { Child } from './child.class';

export class Teacher {
    id : any;
    name : string;
    classrooms: any;
    checkedInClassroom: any;

    constructor(params = null) {
      if (!params) {
      }
      else {
        this.id = params.id;
        if (params.name) this.name = params.name;
        if(params.classroms != undefined) this.classrooms = params.classrooms;
        if(params.checkedInClassroom != undefined) this.checkedInClassroom = params.checkedInClassroom;
      }
    }
}

export class Classroom {
  id: any;
  classname: string;
  students: string[];
  teachers : Teacher[];

  //todo: old AWS remove
  items: any[];
  checkedIn: boolean;

  constructor(params) {
    if (!params) {
      this.id = uuid();
      //throw ("Error: Cannot create a family without a valid id");
    } else {
      this.id = params.id;
      this.classname = params.classname;
      if (params.checkedIn) this.checkedIn = params.checkedIn;
      if (params.students != undefined) {
        this.students = [];      
        this.students = params.students;
      }
      if (params.teachers != undefined) {
        this.teachers = [];      
        for (let [id,teacher] of Object.entries(params.teachers)) {
          this.teachers[id]=new Teacher(teacher);
        }
      }

    }
  }

  public getTeachers(allTeachersMap: Map<string, Teacher>) {    
    if(this.teachers == undefined)  {
      return;
    }
    let result = [];
    for (let [id,teacher] of Object.entries(this.teachers)) {
      result.push(allTeachersMap.get(teacher.id));
    }
    return result;
  }

  public getAvailableTeachers(allTeachersMap: Map<string, Teacher>) {
    if(allTeachersMap == undefined)  {
      return;
    }
    var tempMap = new Map(allTeachersMap);
    let result = [];
    if(this.teachers != undefined) {
      for (let [id,teacher] of Object.entries(this.teachers)) {
        tempMap.delete(teacher.id);
      }
    }
    tempMap.forEach((value: Teacher, key: string) => {      
      result.push(value);      
    });
    return result;
  }

  public getStudents(allStudentsMap: Map<string, Child>) {    
    if(this.students == undefined)  {
      return;
    }
    let result = [];
    for (let [id,child] of Object.entries(this.students)) {
      result.push(allStudentsMap.get(child));
    }
    return result;
  }

  public getAvailableStudents(allStudentsMap: Map<string, Child>) {
    if(allStudentsMap == undefined)  {
      return;
    }
    var tempMap = new Map(allStudentsMap);
    let result = [];
    if(this.students != undefined) {
      for (let [id,child] of Object.entries(this.students)) {
        tempMap.delete(child);
      }
    }
    tempMap.forEach((value: Child, key: string) => {      
      result.push(value);      
    });
    return result;
  } 
}