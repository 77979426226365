import { Globals } from '../../../globals';

//ionic - angular
import { Component, OnInit } from '@angular/core';
import { ToastController, LoadingController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { CameraOptions, CameraSource } from '@capacitor/core';
import { Plugins, CameraResultType } from '@capacitor/core';
const { Camera } = Plugins;

//firebase 
import * as firebase from 'firebase/app';

//frameworks
import * as md5 from 'md5';
import { NgxImageCompressService } from 'ngx-image-compress';

//entities
import { Child } from '../../../classes/child.class';


@Component({
  selector: 'classroom-modal',
  templateUrl: 'classroom.item.modal.html',
  styleUrls: ['classroom.page.scss']
})
export class ClassroomItemModal implements OnInit {

  editItem: any;
  user: string;
  classroom: Child;
  mode: string;

  constructor(private modalController: ModalController,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private imageCompress: NgxImageCompressService,
  ) { }

  ngOnInit() {
    /* 
      If we pass in an 'editItem' property, then we create a copy to store changes to the existing item
      so that the original is not modified unless the user saves.
    */
    console.log(this.editItem);
    console.log(this.mode);
    this.classroom = this.editItem ? Object.assign({}, this.editItem) : new Child()
    //console.log("Edit:" + JSON.stringify(this.child));
  }


  save() {
    var validInput: boolean = true;
    var errMessage: String;

    this.modalController.dismiss({
      /* 
        We pass back either a newItem or editItem value depending on whether an edit operation is taking place
        so that the list module can decide whether to insert into the items array or splice into it.
      */
     classroom: this.classroom
    });
  };

  cancel() {
    this.modalController.dismiss()
  }
  
  getMD5(name = "dummy") {
    return (md5(name));
  }
}