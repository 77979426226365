import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { ChatListPage } from './chat-list.page';
//import { AmdModule } from 'src/app/amd.module';

const routes: Routes = [
  {
    path: '',
    component: ChatListPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
   // AmdModule,
    RouterModule.forChild(routes),
  ],
  declarations: [ChatListPage]
})
export class ChatListPageModule {}
