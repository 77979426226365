import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'journal-menu',
  templateUrl: 'journal.menu.modal.html',
  styleUrls: ['class.page.scss']
})
export class JournalMenuModal implements OnInit {

  broadcast : boolean;
  absent : boolean;
  checkedin : boolean;
  action : any;
  addCategory: any;
  childname : string;

  constructor(
    private modalController: ModalController,
  ) {
  }

  ngOnInit() {
   
  }

  toggleAbsent() {
    this.absent =  !this.absent;
    this.triggerAction("absence");
  }

  toggleCheckin(checkin=null) {
    if(checkin==null)
      this.checkedin = ! this.checkedin;
    else (this.checkedin=checkin)
    this.triggerAction("checkin");
  }

  triggerAction(action, category=null) {
    this.action = action;
    this.addCategory = category;

    this.modalController.dismiss({
        action : this.action,
        type : this.addCategory,
        absent : this.absent,
        checkedin : this.checkedin,
    });
  }
  closeModal() {
    this.modalController.dismiss();
  }
  
}