//todo : delete
import { Globals } from '../../../globals'

//angular - ionic
import { Component, AfterContentInit } from '@angular/core';
import { ModalController, LoadingController, AlertController } from '@ionic/angular';
import { Plugins } from '@capacitor/core';
const { LocalNotifications } = Plugins;

//firebase
import { AngularFirestore } from '@angular/fire/firestore';

//frameworks
import moment = require('moment');

//entities
import { Provider } from '../../../classes/provider.class';
import { NCReminders, Reminder } from '../../../classes/reminder.class';
@Component({
  selector: 'app-provider-reminder',
  templateUrl: 'reminder.page.html',
  styleUrls: ['reminder.page.scss']
})
export class ProviderReminderPage implements AfterContentInit {

  user: any;
  reminders: any;

  authState: any;
  providerDoc: any;
  provider: any;
  loading: boolean;

  constructor(
    public modalController: ModalController,
    private fireStore: AngularFirestore,

  ) {
    this.user = Globals.user;
    this.loading = true;
    var id = this.user.email;

    this.reminders = [];
    //Provider doc

    this.providerDoc = this.fireStore.doc<Account>("providers/" + id);
    this.providerDoc.valueChanges().subscribe(res => {
      this.provider = new Provider(res);
      //console.log(JSON.stringify(this.provider));

      if (!this.provider.reminders) {
        this.reminders = new NCReminders().reminders;
        this.providerDoc.set({
          reminders: Object.assign({},this.reminders)
        }, { merge: true });
      } else {
        this.reminders = this.provider.reminders;
      }
      this.updateNotifications();
      this.loading = false;
    });
  }

  ngAfterContentInit() {
  }

  async ngOnInit() {
  }

  async updateNotifications() {
    LocalNotifications.getPending().then(async (res) => {

      //console.log(res);
      if (res.notifications.length) {
        await LocalNotifications.cancel(res);
      }
      var i : number = 0;
      //loop through reminders and schedule notifications
      for (var key in this.reminders) {
        //console.log(this.reminders[key]);
        var reminder = this.reminders[key];
        const notifs = await LocalNotifications.schedule({
          notifications: [{
              title: "Reminder",
              body: reminder.title + "is due on " + (moment(reminder.nextDue).format("YYYY-MM-DD")),
              id: i++,
              schedule: { at: new Date(reminder.nextDue - (1000 * 60 * 60 * 24 * 7)) },
              //schedule: { at: new Date(new Date().getTime() + 5000) },
              sound: null,
              attachments: null,
              actionTypeId: "",
              extra: null
            }
          ]
        });
      }
    }, err => {
      console.log(err);
    });
  }

  checkStatus(itemId) {
    //console.log("Checking:" + item.title);
    //console.log("Last Completed:"+ this.lastCompleted);
    //console.log("Due:"+ this.nextDue);
    //console.log("Check:"+moment().add(1, "week").format("YYYY-MM-DD"));

    var item = this.reminders[itemId];
    if (!item.lastCompleted || ((moment().add(1, "week").format("YYYY-MM-DD") > item.nextDue) && item.nextDue > item.lastCompleted)) {
      item.badge = "alert-circle";
      item.badgeColor = "danger";
      //console.log(this.title + ":Late");
    } else {
      item.badge = "checkmark-circle";
      item.badgeColor = "success";
      //console.log(this.title + ":Completed");
    }
  }

  setCompleted(itemId) {
    var item = this.reminders[itemId];
    this.checkStatus(itemId);
    if (item.repeatFreq!="custom")
        item.nextDue = moment(item.lastCompleted).add(1, item.repeatFreq).toISOString();
    //console.log(Object.assign({},this.reminders));
    
    this.providerDoc.set({
      reminders: Object.assign({},this.reminders)
    }, { merge: true });
  } 
}
