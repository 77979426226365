import { File } from '@ionic-native/File/ngx';
// Angular - Ionic
import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { Plugins, CameraResultType, CameraOptions, CameraSource, Capacitor } from '@capacitor/core';
import { DomSanitizer } from '@angular/platform-browser';

const { Camera } = Plugins;

//firebase 
import * as firebase from 'firebase/app';

//frameworks
import moment = require('moment');
import { NgxImageCompressService } from 'ngx-image-compress';
import { VideoEditor } from '@ionic-native/video-editor/ngx';
import { v4 as uuid } from 'uuid';

// Entities
import { Journal } from '../../../classes/journal.class';
import { Globals } from '../../../globals';

@Component({
  selector: 'item-modal',
  templateUrl: 'list.item.modal.html',
  styleUrls: ['class.page.scss']
})
export class ListItemModal implements OnInit {

  editItem: Journal;
  childName: string;
  user: string;
  item: Journal;
  showUpload: boolean;
  journaltype: any;
  platform: String;

  attachmentType: String;
  maxDate: string;

  @ViewChild('photoInput', { static: false }) photoInput;
  @ViewChild('videoInput', { static: false }) videoInput;

  constructor(
    private modalController: ModalController,
    public alertController: AlertController,
    private loadingController: LoadingController,
    private imageCompress: NgxImageCompressService,
    private videoEditor: VideoEditor,
    private sanitizer: DomSanitizer
  ) {
    //this.childName = "";
    this.item = this.editItem ? new Journal(this.editItem) : new Journal({});
  }

  ngOnInit() {
    this.platform = Capacitor.getPlatform();
    this.item = this.editItem ? new Journal(this.editItem) : new Journal({});
    if (this.item.type) {
      //edit mode - dont change anything
    } else if (this.journaltype) {
      //new mode
      this.item.type = this.journaltype;
      this.typeSelected();
    }

    this.maxDate = moment().format("yyyy-MM-DD").toString();
    this.enableFileUpload();
    //console.log(this.childName);
    //console.log("Modal:" + JSON.stringify(this.item));
  }

  save() {
    //console.log("Modal before:"+JSON.stringify(this.item));
    this.modalController.dismiss({
      newItem: !this.editItem ? this.item : null,
      editItem: this.editItem ? this.item : null
    });
  };

  typeSelected($event = null) {
    this.item.title = this.item.type;
    switch (this.item.type) {
      case "Sleep Start": {
        this.item.title = "Sleep - Start";
        this.item.description = this.item.description || "Started to sleep";
        break;
      }
      case "Sleep Awake": {
        this.item.title = "Awake";
        this.item.description = this.item.description || "Woke up from sleep";
        break;
      }
      case "Food": {
        this.item.description = this.item.description || "Had food";
        break;
      }
      case "Fluid": {
        this.item.description = this.item.description || "Drank water";
        break;
      }
      case "Diaper": {
        this.item.description = this.item.description || "Had a wet diaper";
        break;
      }
      case "Activity": {
        this.item.description = this.item.description || "Had a fun activity";
        break;
      }
      case "Mood": {
        this.item.description = this.item.description || "In a playful mood";
        break;
      }
    }
  }

  formatDate() {
    var m = moment(this.item.formattedJournalTime);
    this.item.formattedJournalts = m.format("ddd, MMM D YYYY, h:mm a").toString();
    this.item.formattedJournalDate = m.format("ddd, MMM D YYYY").toString()
    this.item.journalts = m.format("YYYYMMDDHHmmSSSS").toString();
  }

  updateNotes(note, macro) {
    this.item.description = note;
    this.item.macro = macro;
  }

  cancel() { 
    if(this.item.fileList != undefined) {   
      for (var i = this.item.fileList.length - 1; i >= 0; i--) {
        this.deleteImage(this.item.fileList[i], i);
      }
    }
    this.modalController.dismiss();
  }

  createFileName() {
    var d = new Date(),
      n = d.getTime(),
      newFileName = uuid() + n + ".jpeg";
    return newFileName;
  }

  fileChangeListener($event) {
    if ($event.target.files && $event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        const localUrl = event.target.result;
        this.compressFile(localUrl);
      }
      reader.readAsDataURL($event.target.files[0]);
    }
  }

  compressFile(image) {
    const imgResultBeforeCompress = image;
    //console.log(imgResultBeforeCompress);
    //console.log('Size in bytes was:', this.imageCompress.byteCount(image));

    this.imageCompress.compressFile(image, -1, 30, 10).then(
      result => {
        //console.log('Size in bytes is now:', this.imageCompress.byteCount(result));
        const imageBlob = this.dataURItoBlob(result.split(',')[1]);
        this.uploadFile(imageBlob);
      }
    );
  }

  async uploadFile(image: Blob, imageCount = 1, totalImages = 1) {
    //input x of y
    //this.presentToast("Uploading ... ");
    var loading = await this.loadingController.create({
      message: 'Uploading file: Please wait...'
    });

    loading.present();

    var storageRef = firebase.storage().ref().child("journal/" + this.item.id + "-" + imageCount + ".jpg");
    var uploadTask = storageRef.put(image);

    uploadTask.on('state_changed', (snapshot) => {
      var progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2);
      loading.message = 'Uploading ' + imageCount + " of " + totalImages + ' : ' + progress + '%';
      //if multiple show x of y
    }, (error) => {
      console.log(error);
      Globals.presentToast("Upload failed :" + error);
      loading.dismiss();
    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        this.item.fileList.push(downloadURL);
        loading.dismiss();
      });
    });
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  uploadFileWithCompress(image, imageCount = 1, totalImages = 1) {
    const imgResultBeforeCompress = image;
    //console.log(imgResultBeforeCompress);
    //console.log('Size in bytes was:', this.imageCompress.byteCount(image));

    this.imageCompress.compressFile(image, -1, 30, 10).then(
      result => {
        //console.log('Size in bytes is now:', this.imageCompress.byteCount(result));
        const imageBlob = this.dataURItoBlob(result.split(',')[1]);
        this.uploadFile(imageBlob, imageCount, totalImages);
      }
    );
  }

  async takePicture() {
    const options: CameraOptions = {
      quality: 70,
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      saveToGallery: false
    }
    Camera.getPhoto(options).then((image) => {
      this.attachmentType = "Camera";
      // imageData is either a base64 encoded string or a file URI
      // If it's base64:
      //console.log("Profile:"+this.item.profile);
      var imageUrl = image.webPath;
      this.uploadFileWithCompress(imageUrl);
      // console.log("image:"+JSON.stringify(image));
      //this.item.profile = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64,'+image.webPath);
      //console.log("Profile:"+this.item.profile);
    }, (err) => {
      // Handle error
      console.log("Camera Error:" + err);
    });
  }

  enableFileUpload() {
    this.attachmentType = "Album";

  }

  enableVideoUpload() {
    this.attachmentType = "Video";

  }

  async uploadVideo(file) {
    const blobReader = new FileReader();
    blobReader.readAsArrayBuffer(file);
    blobReader.onload = async () => {
      let blob: Blob = new Blob([new Uint8Array((blobReader.result as ArrayBuffer))]);

      var loading = await this.loadingController.create({
        message: 'Uploading file: Please wait...'
      });

      loading.present();

      var storageRef = firebase.storage().ref().child("journalvideo/" + this.item.id + ".mp4");
      var uploadTask = storageRef.put(blob);

      uploadTask.on('state_changed', (snapshot) => {
        var progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2);
        loading.message = 'Upload is ' + progress + '% done';
        //if multiple show x of y
      }, (error) => {
        console.log(error);
        Globals.presentToast("Upload failed :" + error);
        loading.dismiss();
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.item.videofile = downloadURL;
          loading.dismiss();
        });
      });      //this.myImages.push(blobURL);
    };
  }

  async loadVideoFromDevice(event) {
    const files = event.target.files;
    for (let file of files) {
      //console.log(file);
      /*if (this.platform === 'ios' || this.platform === 'android') {
        const fileUri = file.name;
        const videoInfo = await this.videoEditor.getVideoInfo({ fileUri });
        let width: number;
        let height: number;

        // video scale
        const ratio = videoInfo.width / videoInfo.height;

        if (ratio > 1) {
          width = videoInfo.width > 480 ? 480 : videoInfo.width;
        } else if (ratio < 1) {
          width = videoInfo.width > 360 ? 360 : videoInfo.width;
        } else if (ratio === 1) {
          width = videoInfo.width > 480 ? 480 : videoInfo.width;
        }

        height = +(width / ratio).toFixed(0);

        this.videoEditor.transcodeVideo({
          fileUri,
          outputFileName: "output.mp4",
          outputFileType: this.videoEditor.OutputFileType.MPEG4,
          width,
          height
        }).then((fileUri: string) => {
          console.log('video transcode success', fileUri);
          this.uploadVideo(fileUri);
        })
          .catch(async(error: any) => {
            console.log('video transcode error', error)
            const alert = await this.alertController.create({
              header: 'Error',
              subHeader: 'Video transcode error!',
              message: error,
              buttons: ['OK']
            });
            await alert.present();
          });
      } else {*/
      var size = file.size / 1024 / 1024;
      if (size > 20) {
        const alert = await this.alertController.create({
          header: 'Video is too big!',
          subHeader: 'Please use a shorter/smaller video',
          message: 'Video size is greater than 20 MB. Please record a shorter video / edit the video before uploading.',
          buttons: ['OK']
        });
        await alert.present();
      } else {
        this.uploadVideo(file);
      }
      //}
    }
  }

  loadImageFromDevice(event) {
    const files = event.target.files;
    var i = 1;
    for (let file of files) {
      const blobReader = new FileReader();
      blobReader.readAsArrayBuffer(file);
      blobReader.onload = () => {
        let blob: Blob = new Blob([new Uint8Array((blobReader.result as ArrayBuffer))]);
        let blobURL: string = URL.createObjectURL(blob);
        this.uploadFileWithCompress(blobURL, i++, files.length);
        //this.myImages.push(blobURL);        
      };
      blobReader.onerror = (error) => {
        console.log(error);
      };
    }
  };

  selectPhotos() {
    this.photoInput.nativeElement.click();
  }

  selectVideo() {
    this.videoInput.nativeElement.click();
  }

  async deleteImage(img, i) {
    var loading = await this.loadingController.create({
      message: 'Removing file: Please wait...'
    });

    loading.present();

    var storage = firebase.storage();
    var fileRef = storage.refFromURL(img);
    fileRef.delete().then(() => {
      console.log("file deleted");
    }).catch((error) => {
      console.log(error)
    });
    if (i > -1) {
      this.item.fileList.splice(i, 1);
    }
    loading.dismiss();
  }
}

