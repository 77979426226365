import { v4 as uuid } from 'uuid';

export class Family {
  id: any;
  providerId: any;
  children: any;

  //todo: old AWS remove
  items: any[];

  constructor(params) {
    if (!params) {
      this.id = uuid();
      //throw ("Error: Cannot create a family without a valid id");
    } else {
      this.id = params.id;
      this.providerId = params.providerId      
      if (params.children != undefined) {
        this.children = {}      
        for (let [id,child] of Object.entries(params.children)) {
          this.children[id]=new Child(child);
        }
      }
    }
  }
}

export class Child {
  id: string;
  name: string;
  fullname: string;
  description: string;
  profile: any;
  profileImg: any;
  profileImgBase64: any;
  email: string;
  phone1: any;
  phone2: any;
  phone3: any;
  phone1type: any;
  phone2type: any;
  phone3type: any;
  isChecked : boolean = true;

  checkedin: boolean;
  absent: boolean;

  constructor(params = null) {
    if (!params) {
      this.id = uuid();
      this.checkedin = false;
      this.absent = false;
    }
    else {
      this.id = params.id;
      this.name = params.name;
      if(params.fullname) this.fullname = params.fullname;
      if(params.description) this.description = params.description;
      if(params.profile) this.profile = params.profile;
      if(params.profileImgBase64)this.profileImgBase64 = params.profileImgBase64;
      if(params.profileImg)this.profileImg = params.profileImg;
      if(params.phone1)this.phone1 = params.phone1;
      if(params.phone2)this.phone2 = params.phone2;
      if(params.phone3)this.phone3 = params.phone3;
      if(params.email)this.email = params.email;
      if(params.phone1type)this.phone1type = params.phone1type;
      if(params.phone2type)this.phone2type = params.phone2type;
      if(params.phone3type)this.phone3type = params.phone3type;
      
      this.absent = params.absent;
      this.checkedin = params.checkedin; 
      this.isChecked = !this.absent;
    }
  }
}