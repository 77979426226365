import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TeacherclassroomPageRoutingModule } from './teacherclassroom-routing.module';

import { TeacherclassroomPage } from './teacherclassroom.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TeacherclassroomPageRoutingModule
  ],
  declarations: [TeacherclassroomPage]
})
export class TeacherclassroomPageModule {}
