// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: true,  
  firebaseConfig: {
    apiKey: "AIzaSyBG-o9-3agoISHgAZKV1eAsMAuB9rN3o8k",
    authDomain: "mydaycare-b8c21.firebaseapp.com",
    databaseURL: "https://mydaycare-b8c21.firebaseio.com",
    projectId: "mydaycare-b8c21",
    storageBucket: "mydaycare-b8c21.appspot.com",
    messagingSenderId: "686751997528",
    appId: "1:686751997528:web:f695965d0c3838c73f342d",
    measurementId: "G-C62CMYM54J"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
