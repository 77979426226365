import { Globals } from '../../../globals'

//angular - firebase
import { Component, AfterContentInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/storage';

//ionic
import { NgxImageCompressService } from 'ngx-image-compress';
import { CameraOptions, CameraSource } from '@capacitor/core';
import { Plugins, CameraResultType } from '@capacitor/core';
import { ModalController, LoadingController, AlertController } from '@ionic/angular';
const { Camera } = Plugins;

//frameworks
import * as md5 from 'md5';

//pages
import { ChildItemModal } from './child.item.modal';

//entities
import { Family, Child } from '../../../classes/child.class';
import { Provider } from '../../../classes/provider.class';
import { AuthService } from '../../../login/auth.service';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-provider-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss']
})
export class ProviderHomePage implements AfterContentInit {

  //old AWS
  data: any;
  providerprofile: any;
  classroom: Map<string, any>;
  itemList: any;
  file: any;
  loading: boolean;

  //new firebase
  user: any;
  role: any;

  providerDoc: any;
  familyCollection: any;
  families: any;
  provider: Provider;
  notInit: boolean;
  enrolled: number;


  constructor(
    private auth: AuthService,
    public modalController: ModalController,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private imageCompress: NgxImageCompressService,
    private fireStore: AngularFirestore,
  ) {
    this.loading = true;

    this.user = Globals.user;
    var id = this.user.email;
    this.families = {};
    this.enrolled = 0;

    //console.log("provider home");
    //console.log(id);

    //Provider doc
    this.providerDoc = this.fireStore.doc<Account>("providers/" + id);
    this.providerDoc.valueChanges().subscribe(res => {
      //console.log("provider doc");
     // console.log(res);

      //if res is undefined --- user not initalized 
      this.notInit = (res == undefined) ? true : false;
      if (this.notInit) return;

      this.provider = new Provider(res);
      this.provider.email = id;
      this.loading = false;
    });

    //this.providerDoc.collectionGroup();

    //Families collection
    var db = firebase.firestore();
    var familyCollection = db.collectionGroup('families').where("providerId", "==", id);
    familyCollection.onSnapshot((querySnapshot) => {
      //console.log("families collection");

      querySnapshot.forEach((doc) => {
        var f = new Family(doc.data())
        f.id = doc.id;
        this.families[f.id] = f;
        // console.log(f);
      });
      this.enrolled = querySnapshot.size;
      //console.log(this.enrolled);
    });
  }

  ngAfterContentInit() {
  }

  ngOnInit() {

  }

  async modify(child, parentEmail) {

    var mode;
    if (parentEmail) {
      child.email = parentEmail;
      mode = "view";
    }
    else {
      mode = "edit";
    }
    let props = {
      editItem: child || undefined,
      mode: mode
    };
    const modal = await this.modalController.create({
      component: ChildItemModal,
      componentProps: props
    });


    // Listen for the modal to be closed...
    modal.onDidDismiss().then(async (result) => {

      //dismissed
      if (!result.data) return;

      var parentEmail = result.data.child.email;
      var child: Child = result.data.child;

      var familyDoc = this.fireStore.doc("families/" + parentEmail);

      //delete
      if (result.data.mode && result.data.mode == "delete") {
        const alert = await this.alertController.create({
          header: "Confirm Delete",
          message: "Are you sure, you want to delete the entry?",
          buttons: [{
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              //console.log('Confirm Cancel');
            }
          }, {
            text: 'Ok',
            handler: async () => {

              await familyDoc.set({
                children: {
                  [child.id]: firebase.firestore.FieldValue.delete()
                }
              }, { merge: true });

              Globals.presentToast("Delete ... complete");
            }
          }]
        });
        await alert.present();
      }

      //update family 
      await familyDoc.set({
        providerId: this.provider.email,
        children: {
          [child.id]: Object.assign({}, child)
        }
      }, { merge: true });

      Globals.presentToast("Save ... complete");
    }
    );

    return await modal.present()
  }

  onSignOut() {
    if(Globals.token) {
      var userDoc = this.fireStore.doc("users/" + Globals.user.email);
      userDoc.set({
        deviceTokens: firebase.firestore.FieldValue.arrayRemove(Globals.token)
      }, { merge: true });  
    }
    Globals.user = "";
    Globals.providerole = false;
    this.auth.logout();
  }

  getMD5(name = "dummy") {
    return md5(name);
  }

  fileChangeListener($event) {
    this.file = $event.target.files[0];
    if ($event.target.files && $event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        const localUrl = event.target.result;
        this.uploadFileWithCompress(localUrl);
      }
      reader.readAsDataURL($event.target.files[0]);
    }
  }

  uploadFileWithCompress(image) {
    const imgResultBeforeCompress = image;
    //console.log(imgResultBeforeCompress);
    //console.log('Size in bytes was:', this.imageCompress.byteCount(image));

    this.imageCompress.compressFile(image, -1, 40, 10).then(
      result => {
        //console.log('Size in bytes is now:', this.imageCompress.byteCount(result));
        const imageBlob = this.dataURItoBlob(result.split(',')[1]);
        this.uploadFile(imageBlob);
      }
    );
  }

  async uploadFile(image: Blob) {
    //this.presentToast("Uploading ... ");
    var loading = await this.loadingController.create({
      message: 'Uploading file: Please wait...'
    });

    loading.present();

    //console.log(this.provider.id);
    var storageRef = firebase.storage().ref().child("provider/" + this.provider.id + ".jpg");
    var uploadTask = storageRef.put(image);

    uploadTask.on('state_changed', (snapshot) => {
      var progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2);
      loading.message = 'Upload is ' + progress + '% done';
    }, (error) => {
      console.log(error);
      Globals.presentToast("Upload failed :" + error);
      loading.dismiss();
    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        //update provider doc
        this.providerDoc.set({
          profilePicture: downloadURL
        }, { merge: true });

        loading.dismiss();
      });
    });
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  setProviderProfilePic() {
    const options: CameraOptions = {
      quality: 70,
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt,
      saveToGallery: false
    }
    Camera.getPhoto(options).then((image) => {
      // imageData is either a base64 encoded string or a file URI
      // If it's base64:
      //console.log("Profile:"+this.item.profile);
      var imageUrl = image.webPath;
      this.uploadFileWithCompress(imageUrl);
      // console.log("image:"+JSON.stringify(image));
      //this.item.profile = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64,'+image.webPath);
      //console.log("Profile:"+this.item.profile);
    }, (err) => {
      // Handle error
      console.log("Camera Error:" + err);
      Globals.presentToast("Upload failed :" + err);
    });
  }
}
