import { Globals } from '../../../globals';

//ionic - angular - firebase
import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, PopoverController } from '@ionic/angular';
import { Media } from '@capacitor-community/media';
import { Capacitor, FilesystemDirectory, Plugins } from '@capacitor/core';
const { Filesystem } = Plugins;
import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';

//frameworks
import * as md5 from 'md5';
import * as moment from 'moment';

// pages
import { Filters } from '../../../classes/filter.class';

// entities
import { Journal, JournalType } from '../../../classes/journal.class';
import { Family } from '../../../classes/child.class';
import { ReactionsPage } from '../reactions/reactions.page';

@Component({
  selector: 'app-list-page',
  templateUrl: 'list.page.html',
  styleUrls: ['list.page.scss']
})
export class ListPage implements OnInit {

  data: any;
  user: any;

  showFilter: boolean;

  filters: Filters;

  journal: Journal | any;
  signedIn: boolean;
  journalIcons: JournalType;
  forceRefresh: any;
  loading: boolean;
  profilePics: any;

  parentEmail: any;
  journalSize: number;
  familyDoc: any;
  notInit: boolean;

  family: any;
  platform: string;
  filterStartDate: any;
  db: any;
  journalArray: any;
  reactionIcons : any;
  reminderArray: any;
  reminderSize: any;

  constructor(
    public modalController: ModalController,
    private loadingController: LoadingController,
    private fireStore: AngularFirestore,
    private popoverCtrl: PopoverController,
  ) {
    //window.addEventListener("contextmenu", (e) => { e.preventDefault(); });
    this.reactionIcons = {
      like : { iconImage: 'assets/img/like.png' },
      love : {iconImage: 'assets/img/love.png' },
      haha : { iconImage: 'assets/img/haha.png' },
      wow : { iconImage: 'assets/img/wow.png'},
      sad : { iconImage: 'assets/img/sad.png' },
      angry : { iconImage: 'assets/img/angry.png' }
    };

    this.journalIcons = new JournalType();

    this.filters = new Filters();
    this.filters.timeFilter = "All";
    this.profilePics = new Map();
    this.platform = Capacitor.getPlatform();

    this.user = Globals.user;
    this.parentEmail = Globals.user.email;

    this.family = {};
    this.journalArray = [];
    
    this.db = firebase.firestore();

    this.getData();
  }

  getData() {
    //this.loading = true;
    //Families doc
    this.familyDoc = this.db.doc('families/' + this.parentEmail);
    this.familyDoc.onSnapshot((doc) => {
      if (!doc.data()) {
        this.notInit = true;
        return;
      }
      this.family = new Family(doc.data());
      //console.log(this.family);
    });

    this.filterStartDate = moment().subtract(14, "days").format("YYYYMMDDHHmmSSSS").toString();

    this.fireStore.collectionGroup('journalItem', ref =>
      ref.where("parentId", "==", this.parentEmail)
       // .where("reminder","!=",true)
        .where("journalts", ">", this.filterStartDate)
        .orderBy("journalts", "desc")
        )
      .valueChanges().subscribe(res => {
        this.journalArray = res;
        //console.log(res);
        var i: number = 0;
        for (let item of this.journalArray) {
          if (item.fileList.length == 0) item.fileList = null;
          item.index = i++
        }
        this.loading = false;
        this.journalSize = this.journalArray.length;
      });

      this.fireStore.collectionGroup('journalItem', ref =>
      ref.where("parentId", "==", this.parentEmail)
        //.where("journalts", ">", this.filterStartDate)
        .where("reminder","==",true)
        .orderBy("journalts", "desc")
        )
      .valueChanges().subscribe(res => {
        this.reminderArray = res;
        //console.log(res);
        var i: number = 0;
        for (let item of this.reminderArray) {
          item.index = i++
        }
       //this.loading = false;
       this.reminderSize = this.reminderArray.length;
    });
  }

  async showReactions(ev, item) {
    //console.log("Reactions...")
    //console.log(ev);

    let reactions = await this.popoverCtrl.create({
      component: ReactionsPage,
      cssClass: 'reactions',
      event: ev,
      translucent: true
    });
    await reactions.present();
    reactions.onDidDismiss().then((reaction) => {
      //console.log(reaction.data, item.id);
      item.reaction = reaction.data;
      this.save(item);
    });

    return;
  }

  like(ev, item) {
    //console.log(ev);
    //console.log("like", item.id);
    if (item.reaction == "like") item.reaction = null;
    else item.reaction = "like";
    this.save(item);
  }

  async save(journalEntry: any) {
    //console.log("Saving :" + JSON.stringify(journalEntry));
    var journalDoc = this.fireStore.doc("journal/" + this.parentEmail).collection("journalItem").doc(journalEntry.id);
    journalDoc.set(
      Object.assign({}, journalEntry),
      { merge: true }
    ).catch(function (error) {
      Globals.presentToast("Error updating document: ", error);
    });
  }

  completeReminder(item) {
    item.reminder = false;
    this.save(item);
  }

  ngOnInit() {
    //console.log("init");
    this.getData();
  }

  ngAfterContentInit() {
    //console.log("after init");
    //this.getData();
  }

  ionViewDidEnter () {
    this.getData();
  }	

  formatDate(date) {
    return moment(date).format("ddd, MMM D");
  }

  showDateBreak(i) {
    if (!i) return true;
    return (this.journalArray[i].formattedJournalDate != this.journalArray[i - 1].formattedJournalDate);
  }

  timefilter($event) {
    //console.log($event.target.value);
    this.filters.timeFilter = $event.target.value;

    if (this.filters.timeFilter === "Today") {
      this.filterStartDate = moment().subtract(1, "days").format("YYYYMMDDHHmmSSSS").toString();
    } else if (this.filters.timeFilter === "This Week") {
      this.filterStartDate = moment().subtract(7, "days").format("YYYYMMDDHHmmSSSS").toString();
    } else
      this.filterStartDate = moment().subtract(30, "days").format("YYYYMMDDHHmmSSSS").toString();

    //console.log("Filter Start:" + this.filterStartDate);
    this.getData();
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
    this.filters.filtersApplied = true;
  }

  getMD5(name = "dummy") {
    return md5(name);
  }

  async downloadFile(imageUrl: string, journalId: String) {
    try {
      const { Filesystem } = Plugins;
      const platform = this.platform;
      // Save the wallpaper locally to Cache folder
      const wallpaperTempFile = journalId + ".jpeg";
      const wallpaperAsBase64 = await this._readAsBase64(imageUrl);
      const wallpaperTemp = await Filesystem.writeFile({
        path: wallpaperTempFile,
        data: wallpaperAsBase64,
        directory: FilesystemDirectory.Cache
      });
      //console.log("Temp file written");
      // Get or create the album
      const albumName = 'MyDayCare';
      let albumIdentifier = '';
      const media = new Media();
      if (platform === 'ios') {
        // Handle albums
        let albums = await media.getAlbums();
        albumIdentifier = albums.albums.find(a => a.name === albumName)?.identifier || null;
        if (!albumIdentifier) {
          // Doesn't exist, create new album
          await media.createAlbum({ name: albumName });
          albums = await media.getAlbums();
          albumIdentifier = albums.albums.find(a => a.name === albumName)?.identifier;
        }
      } else if (platform === 'android') {
        //await media.createAlbum({ name: albumName });
      }
      media.savePhoto({
        path: wallpaperTemp.uri,
        album: (platform === 'ios') ? albumIdentifier : albumName
      })
        .then(() => {
          //console.log('Image has been saved');
          Globals.presentToast("Picture saved to Album");
        })
        .catch(e => {
          //console.error(e);
          Globals.presentToast("Picture saved failed. Retry again");
        });
    } catch (e) {
      console.error(e);
      Globals.presentToast("Picture saved failed. Retry again");
    }
  }
  private async _readAsBase64(url: string): Promise<string> {
    // Fetch the photo, read as a blob, then convert to base64 format
    const response = await fetch(url);
    const blob = await response.blob();
    return await this._convertBlobToBase64(blob);
  }

  private async _convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }
}