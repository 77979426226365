import { Globals } from '../../../globals';

//angular - firebase
import { Component, AfterContentInit, ChangeDetectorRef } from '@angular/core'
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';

//frameworks
import * as md5 from 'md5';

//entities
import { Family} from '../../../classes/child.class';
import { Provider } from '../../../classes/provider.class';
import { AuthService } from '../../../login/auth.service';
import { User } from '../../../classes/user.class';


@Component({
  selector: 'app-page-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss']
})

export class HomePage implements AfterContentInit {

  data: any;
  user: any;
  role: any;
  itemList: any;
  loading: boolean;

  providerDoc: any;
  familyDoc: any;

  families: any;
  provider: any;
  notInit: boolean;
  sendNotification: any;

  constructor(
    private fireStore: AngularFirestore,
    private auth: AuthService,
    private router: Router,
    private chngRef:ChangeDetectorRef
  ) {
    var functions = firebase.app().functions('us-central1');
    this.sendNotification = functions.httpsCallable('sendNotification');

    this.role = Globals.providerole;
    this.user = Globals.user;
    
    var id = this.user.email;
    this.families = {};
    this.provider = {};
    this.notInit = true;
    this.loading = true;
    if (this.user && Globals.providerole) {
      //console.log("Redirecting ... Role:" + JSON.stringify(this.role));
      this.router.navigateByUrl('tabs/providerhome');
    }
    this.chngRef.detach();

    //Families doc
    this.familyDoc = this.fireStore.doc<Family>('families/' + id);
    this.familyDoc.valueChanges().subscribe(res => {
      //console.log("families collection");
      //if res is undefined --- user not initalized 
      this.notInit = (res==undefined)?true:false;
      if(this.notInit) {
        this.loading = false;
        this.chngRef.detectChanges();
        return;
      }

      var f = new Family(res);
      this.families = f;

      //Provider doc
      this.providerDoc = this.fireStore.doc<Account>("providers/" + f.providerId);
      this.providerDoc.valueChanges().subscribe(res => {
        this.provider = new Provider(res);
        //console.log("provider doc");
        //console.log(this.provider);
        this.loading = false;
        this.chngRef.detectChanges();
      });
    });
  }

  ngOnInit() {
  }

  ngAfterContentInit() {
  }

  onSignOut() {
    if(Globals.token) {
      var userDoc = this.fireStore.doc("users/" + Globals.user.email);
      userDoc.set({
        deviceTokens: firebase.firestore.FieldValue.arrayRemove(Globals.token)
      }, { merge: true });  
    }
    Globals.user = "";
    Globals.providerole = false;
    this.auth.logout();
  }
  
  getMD5(name = "dummy") {
    return md5(name);
  }

  pickup(item) {
    //console.log("Pickup", item.name);
    this.sendUserNotification(this.families.providerId, "Parent / Gaurdian of " + item.name + " is at the facility" , "Pickup", "Pickup");  
  }

  sendUserNotification(user, message, title, type) {
    var userDoc = this.fireStore.doc("users/" + user);
    userDoc.ref.get().then(doc => {
      //console.log(doc.data());
      if (!doc.data()) return;

      var userObj = new User(doc.data());
      var data = {
        user: user,
        message: message,
        title: title,
        deviceTokens: userObj.deviceTokens,
        data: {
          type: type
        }
      }
      //console.log(data);
      this.sendNotification(data).then((result) => {
        //console.log(result);
        result.data.result.results.forEach((result, index) => {
          if (result.error) {
            //console.log("invalid token:"+ userObj.deviceTokens[index]);
            userDoc.set({
              deviceTokens: firebase.firestore.FieldValue.arrayRemove(userObj.deviceTokens[index])
            }, { merge: true });
          }
        });
      }).catch((error) => {
        console.log(error);
      });
    });
  }
}
