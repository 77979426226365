import { IonicModule } from '@ionic/angular';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProviderReminderPage } from './reminder.page';
import { Globals } from '../../../globals';
import { ProviderReminderRoutingModule } from './reminder.routing';
import { HttpClientModule } from '@angular/common/http';
import { AngularFirestoreModule } from '@angular/fire/firestore';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ProviderReminderRoutingModule,
    HttpClientModule,
  ],
  declarations: [
    ProviderReminderPage,
    
  ],
  entryComponents: [
   ],
  providers: [
    Globals
  ]
})
export class ProviderReminderModule {}
