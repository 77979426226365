// Angular - Ionic
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController } from '@ionic/angular';
import { ToastController, LoadingController } from '@ionic/angular';

//firebase 
import * as firebase from 'firebase/app';

//frameworks
import moment = require('moment');
import { v4 as uuid } from 'uuid';
import { Provider } from '../../classes/provider.class';

// Entities
import { User } from '../../classes/user.class';
import { Globals } from '../../globals';

@Component({
  selector: 'user-modal',
  templateUrl: 'user.item.modal.html',
})
export class UserItemModal implements OnInit {

  item: User;
  user : User;
  showUpload: boolean;
  journaltype: any;
  provider: Provider;
  userDoc: any;
  providerDoc: any;

  constructor(
    private modalController: ModalController,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private fireStore: AngularFirestore,
  ) {
    //this.childName = "";
  }

  ngOnInit() {
    this.user= new User({});
    this.userDoc = this.fireStore.doc('users/' + this.item.id);
    this.userDoc.valueChanges().subscribe((res) => {
      this.user = new User(res);
      this.user.id = this.item.id;
    });

    if(this.item.role=="provider") {
      this.getProvider(this.item.id);
    }
  }

  getProvider(providerId){
    this.provider= new Provider();
    this.providerDoc = this.fireStore.doc('providers/' + providerId);
    this.providerDoc.valueChanges().subscribe((res) => {
      this.provider = new Provider(res);
    });
  }

  deleteToken(i) {
    this.user.deviceTokens.splice(i, 1);
  }

  save() {
    this.userDoc.set(Object.assign({}, this.user), { merge: true });
    this.providerDoc.set(Object.assign({},this.provider), { merge: true });
    Globals.presentToast("Updates Saved !");
  };

  cancel() {
    this.modalController.dismiss();
  }
}