import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Classroom, Teacher } from '../../../classes/classroom.class';
import * as firebase from 'firebase/app';
import { Globals } from '../../../globals';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from '../../../classes/user.class';

@Component({
  selector: 'classroom-teacher-modal',
  templateUrl: 'classroom.teacher.modal.html',
  styleUrls: ['classroom.page.scss']
})
export class ClassroomTeacherModal implements OnInit {

  editItem: any;
  user: string;
  newTeacher: any;
  mode: string;
  providerId: string;
  classroom: Classroom;
  classroomCollection: any;
  teachersCollection: any;
  allTeachersMap: Map<string, Teacher>;

  toAdd: Set<string>;
  toRemove: Set<string>;

  constructor(private modalController: ModalController,
    private fireStore: AngularFirestore
    ) { }

  ngOnInit() {
    this.newTeacher = this.editItem ? Object.assign({}, this.editItem) : new Teacher();
    this.toAdd = new Set<string>();
    this.toRemove = new Set<string>();
  }


  save() {
    var validInput: boolean = true;
    var errMessage: String;
    this.teachersCollection.doc(this.newTeacher.id).set({
      name: this.newTeacher.name,
      id: this.newTeacher.id
    });
    let teacherObj = this.teachersCollection.doc(this.newTeacher.id);
    let classroomDoc = this.classroomCollection.doc(this.classroom.classname);
    classroomDoc.update({
      teachers: firebase.firestore.FieldValue.arrayUnion(teacherObj.ref)
    });

    teacherObj.update({
      classrooms: firebase.firestore.FieldValue.arrayUnion(classroomDoc.ref)
    });

    var userDoc = this.fireStore.doc('users/' + this.newTeacher.id.trim());
    var user = new User({});
    user.role = "commercial-teacher";
    user.id = this.newTeacher.id.trim();
    user.providerId = this.providerId;
    userDoc.set(Object.assign({}, user), { merge: true });

    this.modalController.dismiss();
    Globals.presentToast("Save ... complete");
  };

  cancel() {
    this.modalController.dismiss()
  }

  /**
   * Captures and console logs the value emitted from the user depressing the accordion component's <ion-button> element 
   * @public
   * @method captureName
   * @param {any}		event 				The captured event
   * @returns {none}
   */
  public captureName(event: any): void {
    console.log(`Captured name by event value: ${event}`);
  }

  selectionChanged($event, teacherId) {
    if ($event.target.checked == true) {
      this.toRemove.delete(teacherId);
      this.toAdd.add(teacherId);
    } else {
      this.toAdd.delete(teacherId);
      this.toRemove.add(teacherId);
    }
  }

  async saveTeachers() {
    let classroomDoc = this.classroomCollection.doc(this.classroom.classname);
    // handle additions
    if (this.toAdd.size > 0) {
      let tempArr = [];
      this.toAdd.forEach(element => {
        var teacherDoc = this.teachersCollection.doc(element);
        tempArr.push(teacherDoc.ref);
        teacherDoc.update({
          classrooms: firebase.firestore.FieldValue.arrayUnion(classroomDoc.ref)
        });
      });
      classroomDoc.update({
        teachers: firebase.firestore.FieldValue.arrayUnion(...tempArr)
      });      
    }
    // handle removals
    if (this.toRemove.size > 0) {
      let tempArr = [];
      this.toRemove.forEach(element => {
        var teacherDoc = this.teachersCollection.doc(element);
        tempArr.push(teacherDoc.ref);
        teacherDoc.update({
          classrooms: firebase.firestore.FieldValue.arrayRemove(classroomDoc.ref)
        });
      });
      classroomDoc.update({
        teachers: firebase.firestore.FieldValue.arrayRemove(...tempArr)
      });
    }

    this.modalController.dismiss();
    Globals.presentToast("Save ... complete");
  }
}