import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '../../classes/user.class';
import { AuthService } from '../auth.service';
import { PasswordValidator } from './password.validator';

@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss'],
})
export class RegisterPage implements OnInit {

  email: FormControl;
  matching_passwords_group: FormGroup;
  validations_form: FormGroup;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService : AuthService,
    private fireStore: AngularFirestore
  ) { }

  ngOnInit() {
    this.email = new FormControl('', Validators.compose([
      Validators.required,
      Validators.email
      //Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-z]{2,4}$')
    ]));
    this.matching_passwords_group = new FormGroup({
      password: new FormControl('', Validators.compose([
        Validators.minLength(6),
        Validators.required,
        //Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$')
      ])),
      confirm_password: new FormControl('', Validators.required)
    }, (formGroup: FormGroup) => {
      return PasswordValidator.areEqual(formGroup);
    });

    this.validations_form = this.formBuilder.group({
      email: this.email,
      matching_passwords: this.matching_passwords_group
    });
  }

  validation_messages = {
    'email': [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Please enter a valid email.' },
      { type: 'email', message: 'Please enter a valid email.' }
    ],
    'password': [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'Password must be at least 6 characters long.' },
      //{ type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, and one number.' }
    ],
    'confirm_password': [
      { type: 'required', message: 'Confirm password is required.' }
    ],
    'matching_passwords': [
      { type: 'areEqual', message: 'Password mismatch.' }
    ]
  };

  async signup(email, password) {
    var  strEmail : String= email.value;      
    //console.log("Email:"+strEmail.trim().toLowerCase());
    await this.authService.signUpWithEmail(strEmail.trim().toLowerCase(), password.value);
    var userDoc = this.fireStore.doc('users/' + strEmail.trim().toLowerCase());
    var user= new User({});
    user.role = "parent";
    user.id=strEmail.trim().toLowerCase();
    userDoc.set(Object.assign({}, user), { merge: true });
  }

  signin() {
    this.router.navigate(['login/email']);
  }

}
