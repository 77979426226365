import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as firebase from 'firebase/app';
require ("firebase/functions")

import { defineCustomElements } from '@ionic/pwa-elements/loader';

firebase.initializeApp(environment.firebaseConfig);

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
