import { v4 as uuid } from 'uuid';

export class ChatRoom {
  id: any;
  userId: string;
  providerId: string;
  userType: string;
  childList: string[];

  constructor(params = null) {
    if (!params) {
      this.id = uuid();
    } else {
      this.id = params.id;
      this.providerId = params.providerId;
      this.userId = params.userId;
      this.userType = params.userType;
      this.childList = params.childList;
    }
  }
}


export class ChatMessage {
  id: any;
  userId: string;
  providerId: string;
  message: string;
  authorRole: string;
  timestamp: any;
  read: boolean;
  ts: any;

  constructor(params = null) {
    if (!params) {
      this.id = uuid();
    } else {
      this.id = params.id;
      this.providerId = params.providerId;
      this.userId = params.userId;
      this.message = params.message;
      this.authorRole = params.authorRole;
      this.timestamp = params.timestamp;
      this.read = params.read;
      if(params.ts) this.ts=params.ts;
    }
  }
}