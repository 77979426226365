import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth, User } from 'firebase/app';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Globals } from '../globals';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private afAuth: AngularFireAuth,
    private router: Router,
    private ngZone: NgZone,
    private toastController: ToastController) {
  }
/*
  signInWithGoogle() {
    return this.afAuth.signInWithPopup(new auth.GoogleAuthProvider())
      .then((res) => {
        //console.log('AuthService::Successful Google login', res);
        return this.ngZone.run(() => this.router.navigate(['/']));
      }).catch(err => {
        this.presentError(err.message);
        //console.log('AuthService::Failed Google login', err);
      });
  }

  signInWithGithub() {
    return this.afAuth.signInWithPopup(new auth.GithubAuthProvider())
      .then((res) => {
        //console.log('AuthService::Successful Github login', res);
        return this.ngZone.run(() => this.router.navigate(['/']));
      }).catch(err => {
        this.presentError(err.message);
        //console.log('AuthService::Failed Github login', err);
      });
  }*/

  async signInWithEmail(email: string, password: string) {
    var cred = firebase.auth.EmailAuthProvider.credential(email,password);
    //console.log("signin method")
    return firebase.auth().signInWithCredential(cred)
      .then(async (res) => {
        //console.log('AuthService::Successful Email login', res);
        const user = await this.isLoggedIn();
        //console.log(user);
        if (user.emailVerified || user.email=="test@test.com") {
          Globals.user = user;
          return this.ngZone.run(() => this.router.navigate(['/']));
        } else {
          return this.ngZone.run(() => this.router.navigate(['verify']));
        }
      }).catch(err => {
        this.presentError(err.message);
        console.log('AuthService::Failed Email login', err);
      });
  }

  async signUpWithEmail(email: string, password: string) {
    return await this.afAuth.createUserWithEmailAndPassword(email, password)
    .then((res) => {
      this.sendEmailVerification();
    }).catch(err => {
      this.presentError(err.message);
      //console.log("AuthService::sign up with email failed", err);
    });    
  }

  forgotPasswordEmail(email: string) {
    return this.afAuth.sendPasswordResetEmail(email)
      .then((res) => {
        //console.log('AuthService::Successfully sent password reset email');
        return this.ngZone.run(() => this.router.navigate(['/']));
      }).catch(err => {
        this.presentError(err.message);
        //console.log("AuthService::Password reset email failed", err);
      });
  }

  logout() {
    this.afAuth.signOut().then((res) => {
      return this.ngZone.run(() => this.router.navigate(['login']));
    }
    );
  }

  async sendEmailVerification() {
    const user = await this.isLoggedIn();
    if (user && user.uid) {
      user.sendEmailVerification();
      return this.ngZone.run(() => this.router.navigate(['verify']));
    }
  }

  isLoggedIn() {
    return this.afAuth.currentUser;
  }

  async presentError(errorMsg: string) {
    const toast = await this.toastController.create({
      message: errorMsg,
      duration: 5000,
      position: 'bottom',
      buttons: [
        {
          icon: 'close-outline',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }
}