import { ClassroomTeacherModal } from './classroom.teacher.modal';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ClassroomPageRoutingModule } from './classroom-routing.module';

import { ProviderClassRoomPage } from './classroom.page';
import { ClassroomItemModal } from './classroom.item.modal';
import { AccordionListComponent } from '../../../accordion-list/accordion-list.component';
import { ClassroomStudentModal } from './classroom.student.modal';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ClassroomPageRoutingModule
  ],
  declarations: [
    ProviderClassRoomPage,
    ClassroomItemModal,
    ClassroomTeacherModal,
    ClassroomStudentModal,
    AccordionListComponent    
  ],
  entryComponents : [
    ClassroomItemModal,
    ClassroomTeacherModal,
    ClassroomStudentModal
  ]
})
export class ClassroomPageModule {}
