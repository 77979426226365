import { Component, OnInit } from '@angular/core';
import { Globals } from '../../globals';
import { AuthGuardService } from '../../services/auth-route-guard'
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

import { AuthService } from '../../login/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from '../../classes/user.class';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { HelpModal } from '../provider/profile/help.modal';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-page-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})
export class TabsPage {
  providerRole: boolean;
  role: string;
  authState: any;
  user: string;
  userObj : any;
  authService: AuthGuardService;
  chatBadge: boolean;
  commercial: boolean;
  teacherRole: boolean;

  constructor(
    public auth: AuthService,
    public guard: AuthGuardService,
    private router: Router,
    private afAuth: AngularFireAuth,
    private fireStore: AngularFirestore,
    private ga: GoogleAnalytics,
    public modalController: ModalController,
  ) {
    this.authService = guard;
    firebase.auth().onAuthStateChanged(user => {


      //this.afAuth.authState.subscribe(user => {
      if (user && user.uid) {
        Globals.user = user
        this.ga.setUserId(user.email);
        this.ga.trackView("tabs");
        //Emulate hook -- begin
        //console.log("Emulating dummy user ...")
        //Globals.user = {email : "test@test.com"};
        //console.log(Globals.user);
        //Emulate hook -- end 

        Globals.signedIn = true;
        this.user = Globals.user;

        var userDoc = this.fireStore.doc("users/" + Globals.user.email);
        userDoc.valueChanges().subscribe(res => {
          if(res==undefined) return;

          this.userObj = new User(res);
          if (this.userObj.role) {
            if (this.userObj.role == "provider") {
              this.ga.setVar("role", "provider");
              Globals.providerole = true;
              this.providerRole = true;
            }
            else if (this.userObj.role == "commercial-provider") {
              this.ga.setVar("role", "commercial-provider");
              Globals.providerole = true;
              Globals.teacherrole = false;
              Globals.commercial = true;
              this.providerRole = true;
              this.teacherRole = false;
              this.commercial = true;
            }
            else if (this.userObj.role == "commercial-teacher") {
              this.ga.setVar("role", "commercial-teacher");
              Globals.providerole = false;
              Globals.teacherrole = true;
              Globals.commercial = true;
              Globals.providerId = this.userObj.providerId;
              this.providerRole = false;
              this.teacherRole = true;
              this.commercial = true;
            }
            else {
              this.ga.setVar("role", "parent");
              this.providerRole = false;
            }
          }
          //console.log(userObj);

        });
        userDoc.set({
          lastLogin: new Date()
        }, { merge: true });

        if (Globals.token) {
          //console.debug("Push notification token:" + Globals.token);
          userDoc.set({
            deviceTokens: firebase.firestore.FieldValue.arrayUnion(Globals.token)
          }, { merge: true });
        }
      } else {
        //console.debug('user not logged in');
        // Globals.user = null
      }
    });

    if (Globals.providerole == undefined) {
      //wait till initialization if launched through login
      setTimeout(() => {
      }, 300);
    }
  }


  async showHelp() {
    const modal = await this.modalController.create({
      component: HelpModal,
      componentProps : {}
    });
        
    modal.onDidDismiss().then(async (result) => {     
    });

    return await modal.present()
  }

  ngOnInit() {
    this.chatBadge = Globals.chatBadge;
    
    if (Globals.providerole == undefined) {
      //wait till initialization if launched through login
      setTimeout(() => {
        //console.log(this.userObj.dontShowHelpFlag )
        if (this.userObj.dontShowHelpFlag != true) {
          this.showHelp();
        }
      }, 2000);
    }
 
  }
}