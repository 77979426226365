import { Globals } from '../../../globals';

//ionic - angular - firebase
import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, Platform, ActionSheetController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';

//frameworks
import * as md5 from 'md5';
import * as moment from 'moment';

// pages
import { Filters } from '../../../classes/filter.class';
import { ListItemModal } from '../journal/list.item.modal';

// entities
import { Journal, JournalMap, JournalType } from '../../../classes/journal.class';
import { Capacitor } from '@capacitor/core';
import { User } from '../../../classes/user.class';

@Component({
  selector: 'provider-journal-page',
  templateUrl: 'journal.page.html',
  styleUrls: ['approval.page.scss']
})
export class ProviderJournalPage implements OnInit {

  //props
  child: any;
  parentEmail: any;

  modal: any;
  data: any;
  user: any;

  showFilter: boolean;
  filters: Filters;
  platform: any;


  journalArray: any;

  globals: Globals;
  journalIcons: JournalType;
  loading: boolean;
  journalSize: number;
  filterStartDate: any;

  db: firebase.firestore.Firestore;
  reactionIcons: any;
  sendNotification: any;
  functions: firebase.functions.Functions;

  constructor(
    public modalController: ModalController,
    private loadingController: LoadingController,
    public actionSheetController: ActionSheetController,
    private fireStore: AngularFirestore,
  ) {
    this.reactionIcons = {
      like : { iconImage: 'assets/img/like.png' },
      love : {iconImage: 'assets/img/love.png' },
      haha : { iconImage: 'assets/img/haha.png' },
      wow : { iconImage: 'assets/img/wow.png'},
      sad : { iconImage: 'assets/img/sad.png' },
      angry : { iconImage: 'assets/img/angry.png' }
    };
    
    this.journalIcons = new JournalType();
    this.filters = new Filters();
    this.filters.timeFilter = "All";
    this.loading = true;
    this.journalArray = [];
    this.db = firebase.firestore();
  }

  async ngOnInit() {

    //read journal doc
    this.platform = Capacitor.getPlatform();

    this.user = Globals.user;
    this.journalArray = [];
    this.functions = firebase.app().functions('us-central1');
    this.sendNotification = this.functions.httpsCallable('sendNotification');
    this.getData();
  }

  getData() {


    this.fireStore.collectionGroup('journalItem', ref => ref.where("parentId", "==", this.parentEmail).where("approval","==", false)
      .where("childId", "==", this.child.id)
      //.where("journalts", ">", this.filterStartDate)
      .orderBy("journalts", "desc"))
      .valueChanges().subscribe(res => {
        //console.log(res);
        this.journalArray = res;
        var i = 0;
        for (let item of this.journalArray) {
          item.index = i++
        }
        this.loading = false;
        this.journalSize = this.journalArray.length;
      });
  }

  approveAll() {
    for (let item of this.journalArray) {
      item.approval = true;
      this.save(item);
    }
  }

  approve (itemIndex) {
    this.journalArray[itemIndex].approval = true;
    this.save(this.journalArray[itemIndex]);
  }

  reject (itemIndex) {
    this.delete(this.journalArray[itemIndex]);
  }
   
  showDateBreak(i) {
    if (!i) return true;
    return !((this.journalArray[i].formattedJournalDate) == (this.journalArray[i - 1].formattedJournalDate));
  }

  formatDate(date) {
    return moment(date).format("ddd, MMM D");
  }

  async delete(journalEntry) {
    Globals.presentToast("Deleting ...");
    // console.log("Deleting :" + JSON.stringify(list));
    //console.log(journalEntry);

    var journalDoc = this.fireStore.doc("journal/" + this.parentEmail).collection("journalItem").doc(journalEntry.id);
    journalDoc.delete().then(function () {
      //console.log("Document successfully deleted!");
      Globals.presentToast("Delete ... complete");

    }).catch(function (error) {
      console.error("Error removing document: ", error);
    });
  }

  async save(journalEntry: Journal) {
    Globals.presentToast("Saving ...");
    //console.log("Saving :" + JSON.stringify(journalEntry));
    var journalDoc = this.fireStore.doc("journal/" + this.parentEmail).collection("journalItem").doc(journalEntry.id);
    journalDoc.set(
      Object.assign({}, journalEntry),
      { merge: true }
    ).catch(function (error) {
      console.error("Error removing document: ", error);
    });

    if(journalEntry.approval) {
      this.sendUserNotification(journalEntry.parentId, "Your child has a new Journal Entry : " + journalEntry.description, "New Journal Entry", "journal");
    }

    Globals.presentToast("Save ... complete");
  }


  closeModal() {
    this.modalController.dismiss();
  }
  getMD5(name = "dummy") {
    return md5(name);
  }


  sendUserNotification(user, message, title, type) {
    var userDoc = this.fireStore.doc("users/" + user);
    userDoc.ref.get().then(doc => {
      //console.log(doc.data());
      if (!doc.data()) return;

      var userObj = new User(doc.data());
      var data = {
        user: user,
        message: message,
        title: title,
        deviceTokens: userObj.deviceTokens,
        data: {
          type: type
        }
      }
      //console.log(data);
      this.sendNotification(data).then((result) => {
        //console.log(result);
        result.data.result.results.forEach((result, index) => {
          if (result.error) {
            //console.log("invalid token:"+ userObj.deviceTokens[index]);
            userDoc.set({
              deviceTokens: firebase.firestore.FieldValue.arrayRemove(userObj.deviceTokens[index])
            }, { merge: true });
          }
        });
      }).catch((error) => {
        console.log(error);
      });
    });
  }
}

