import {
    Directive,
    ElementRef,
    Input,
    AfterViewInit,
    NgZone,
    EventEmitter,
    Output
} from "@angular/core";
import { GestureController } from "@ionic/angular";
import { AnyMxRecord } from "dns";

@Directive({
    selector: "[long-press]"
})
export class LongPressDirective implements AfterViewInit {

    @Output() press = new EventEmitter();
    @Input("delay") delay =  500;
    action: any; //not stacking actions

    event : any;

    private longPressActive = false;

    constructor(
                    private el: ElementRef, 
                    private gestureCtrl: GestureController, 
                    private zone: NgZone
                ) {
                }
    
    ngAfterViewInit() {
        this.loadLongPressOnElement();
    }    

    loadLongPressOnElement() {

        const gesture = this.gestureCtrl.create({
            el: this.el.nativeElement,
            threshold: 0,
            gestureName: 'long-press',          
            onStart: ev => {                    
                this.longPressActive = true;
                this.event = {
                    target : {
                      getBoundingClientRect : () => {
                        return {
                          top: ev.startY,
                          left : ev.startX
                        };
                      }
                    }         
                };    
                this.longPressAction();
            },
            onEnd: ev => {      
                //this.event=ev;
                /*this.event = {
                         MouseEvent : {
                             isTrusted : true,
                             x : ev.startX,
                             y : ev.startY
                         }
                };*/
                 
                this.longPressActive = false;
            }
        });
        gesture.enable(true);
    }
    
    private longPressAction() {
        if (this.action) {
            clearInterval(this.action);
        }
        this.action = setTimeout(() => {
            this.zone.run(() => {
                if (this.longPressActive === true) {
                    this.longPressActive = false;
                    this.press.emit(this.event);
                }
            });
        }, this.delay);
    }
}
