import { AuthService } from '../auth.service';
import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.page.html',
  styleUrls: ['./verify-email.page.scss'],
})
export class VerifyEmailPage implements OnInit {

  flag : boolean;

  constructor(private authService : AuthService,
    private router: Router,
    private ngZone: NgZone) { 
      this.flag = true;
    }

  ngOnInit() {
    if (this.flag) {
      this.verify();
      this.flag = false;
    }
  }

  async verify() {
    const user = await this.authService.isLoggedIn();
    if (user.emailVerified || user.email=="test@test.com") {
      console.log(true);
      return this.ngZone.run(() => this.router.navigate(['/']));
    } else {
      //console.log("Verify");
      return this.ngZone.run(() => this.router.navigate(['verify']));
    }
  }

  signout() {
    this.authService.logout();
  }
}
