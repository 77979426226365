import { Injectable } from '@angular/core';
import { Router, CanActivate, convertToParamMap } from '@angular/router';
import { Globals } from '../globals';

@Injectable({ providedIn: 'root' }
)
export class AuthGuardService implements CanActivate {

  signedIn: boolean = false;

  constructor(
    public router: Router,
  ) {
  }

  canActivate() {
    return Globals.signedIn;
  }
}