import { ClassroomTeacherModal } from './classroom.teacher.modal';
import { Teacher, Classroom } from './../../../classes/classroom.class';
import { Globals } from '../../../globals';

import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import * as firebase from 'firebase/app';

//ionic
import { ModalController, LoadingController, ToastController, ActionSheetController, AlertController } from '@ionic/angular';

//frameworks
import * as md5 from 'md5';
import { v4 as uuid } from 'uuid';


//entities
import { ClassroomItemModal } from './classroom.item.modal';


import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { Child, Family } from '../../../classes/child.class';
import { ClassroomStudentModal } from './classroom.student.modal';

const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
@Component({
  selector: 'app-classroom-page',
  templateUrl: 'classroom.page.html',
  styleUrls: ['classroom.page.scss']
})

export class ProviderClassRoomPage implements OnInit {

  faBullhorn = faBullhorn;

  user: any;
  functions: any;
  broadcast: boolean;

  classrooms : any;
  classRoomCollection: any;
  allTeachersMap: Map<string, Teacher>;
  teachersCollection: any;
  allStudentsMap: Map<string, Child>;
  studentsCollection: any;
  families: any;
  loading: boolean;
  notInit: boolean;

  constructor(
    public modalController: ModalController,
    private loadingController: LoadingController,
    private actionSheetController: ActionSheetController,
    private fireStore: AngularFirestore,
    private fireFunctions: AngularFireFunctions,
    private alertController : AlertController
  ) {
    this.user = Globals.user;
    var id = this.user.email;
    this.families = {};

    //Families collection
    var db = firebase.firestore();
    
    console.log(id);
    this.teachersCollection = this.fireStore.doc("providers/" + id).collection('teachers');
    this.teachersCollection.valueChanges().subscribe(res => {
      this.allTeachersMap = new Map();
      res.forEach( teacher => {        
        this.allTeachersMap.set(teacher.id, new Teacher(teacher));
      })
    });
    var familyCollection = db.collectionGroup('families').where("providerId", "==", id);
    familyCollection.onSnapshot((querySnapshot) => {
      if (querySnapshot.size>0) {
        this.families = {};
        this.allStudentsMap = new Map();
      }
      querySnapshot.forEach((doc) => {
        var f = new Family(doc.data())
        f.id = doc.id;
        this.families[f.id] = f;        
        for (let [id,student] of Object.entries(f.children)) {
          this.allStudentsMap.set(id, new Child(student));
        }
      });
    });
    this.classRoomCollection = this.fireStore.doc("providers/" + id).collection('classroom');
    this.classRoomCollection.valueChanges().subscribe(res => {        
        this.classrooms = [];
       // if (res.length == 0) this.notInit = true;
        res.forEach ( classroom => {
            let classroomObj = new Classroom(classroom)
            this.classrooms.push(classroomObj);
            
        });
    });
  }

  async ngOnInit() {
  }

  async modify(classroom) {
    var mode;
    if (classroom) {
      mode = "view";
    }
    else {
      mode = "edit";
    }
    let props = {
      editItem: classroom || undefined,
      mode: mode
    };
    const modal = await this.modalController.create({
      component: ClassroomItemModal,
      componentProps: props
    });


    // Listen for the modal to be closed...
    modal.onDidDismiss().then(async (result) => {

      //dismissed
      if (!result.data) return;

      var classroom: Classroom = result.data.classroom;

      var classroomDoc = this.classRoomCollection.doc(classroom.classname);

      //delete
      if (result.data.mode && result.data.mode == "delete") {
        const alert = await this.alertController.create({
          header: "Confirm Delete",
          message: "Are you sure, you want to delete the entry?",
          buttons: [{
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              //console.log('Confirm Cancel');
            }
          }, {
            text: 'Ok',
            handler: async () => {

              await classroomDoc.delete().then(function () {
                //console.log("Document successfully deleted!");
                Globals.presentToast("Delete ... complete");
              }).catch(function (error) {
                console.error("Error removing classroom: ", error);
              });
            }
          }]
        });
        await alert.present();
      }

      //update family 
      await classroomDoc.set({
        classname: classroom.classname
      }, { merge: true });

      Globals.presentToast("Save ... complete");
    }
    );

    return await modal.present()
  }

  async removeTeacher(classroom: Classroom, teacher: Teacher) {
    let removedTeacherObj = this.teachersCollection.doc(teacher.id);
    let  classroomDoc  = this.classRoomCollection.doc(classroom.classname);
    classroomDoc.update({
      teachers: firebase.firestore.FieldValue.arrayRemove(removedTeacherObj.ref)
    });
  }

  async saveStudents($event, classroomId) {
    let  classroomDoc  = this.classRoomCollection.doc(classroomId);
    let selectedStudents = $event.detail.value;
    let selectedStudentsObj = [];
    selectedStudents.forEach(async student => {
      selectedStudentsObj.push(student);      
    });
    classroomDoc.update({
      students: firebase.firestore.FieldValue.arrayUnion(...selectedStudentsObj)
    });
  }  

  async removeStudent(classroom: Classroom, student: Child) {
    let  classroomDoc  = this.classRoomCollection.doc(classroom.classname);
    classroomDoc.update({
      students: firebase.firestore.FieldValue.arrayRemove(student.id)
    });
  }

  async manageTeachers(classroom) {
    var mode;
    if (classroom) {
      mode = "view";
    }
    else {
      mode = "edit";
    }
    let props = {
      mode: mode,
      classroom: classroom,      
      providerId: this.user.email,
      classroomCollection: this.classRoomCollection,
      teachersCollection: this.teachersCollection,
      allTeachersMap: this.allTeachersMap
    };
    const modal = await this.modalController.create({
      component: ClassroomTeacherModal,
      componentProps: props
    });


    // Listen for the modal to be closed...
    modal.onDidDismiss().then(async (result) => {      
    });

    return await modal.present()
  }

  async manageStudents(classroom) {
    var mode;
    if (classroom) {
      mode = "view";
    }
    else {
      mode = "edit";
    }
    let props = {
      mode: mode,
      classroom: classroom,
      classroomCollection: this.classRoomCollection,
      studentsCollection: this.studentsCollection,
      allStudentsMap: this.allStudentsMap
    };
    const modal = await this.modalController.create({
      component: ClassroomStudentModal,
      componentProps: props
    });


    // Listen for the modal to be closed...
    modal.onDidDismiss().then(async (result) => {      
    });

    return await modal.present()
  }

  async presentDeleteClassroomAlert(classroom: Classroom) {
    const alert = await this.alertController.create({
      header: 'Delete',
      subHeader: classroom.classname,
      message: 'This operation cannot be reverted!',
      buttons: [{
        text: 'Cancel',
        role: 'cancel'
      }, {
        text: 'Proceed',
        handler: async() => {
          var classroomDoc = this.classRoomCollection.doc(classroom.classname);   
          await classroomDoc.delete().then(function () {
            Globals.presentToast("Delete ... complete");
          }).catch(function (error) {
            console.error("Error removing classroom: ", error);
          });       
        }
      }]
    });
    await alert.present();
  }

}