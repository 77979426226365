import { Globals } from '../../../globals';

//ionic - angular
import { Component, OnInit } from '@angular/core';
import { ToastController, LoadingController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

//firebase 
import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'help-modal',
  templateUrl: 'help.modal.html',
  styleUrls: ['home.page.scss']
})
export class HelpModal implements OnInit {

  
  showHelpFlag : boolean;
  userDoc : any;

  constructor(private modalController: ModalController,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private fireStore: AngularFirestore,
  ) { }

  ngOnInit() {
    //Provider doc
    this.userDoc = this.fireStore.doc("users/" + Globals.user.email);
    this.userDoc.valueChanges().subscribe(res => {
      if (res == undefined) return;
      this.showHelpFlag = res.dontShowHelpFlag;
    });
  }

  close() {
    //save don't show flag
    this.userDoc.set({
      dontShowHelpFlag: this.showHelpFlag
    }, { merge: true }); 

    this.modalController.dismiss();
  }

}