import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';

import * as firebase from 'firebase/app';
import * as moment from 'moment';

import { ChatMessage, ChatRoom } from '../../../classes/chat.class';
import { User } from '../../../classes/user.class';
import { Globals } from '../../../globals';

@Component({
  selector: 'app-chat-room',
  templateUrl: './chat-room.page.html',
  styleUrls: ['./chat-room.page.scss'],
})
export class ChatRoomPage implements OnInit {
  chatRoom: any;
  chat: string;
  unsubscribe: any;
  messages: any[] = [];
  chatKeys: any = [];
  userType: string;
  loading: boolean = true;
  sendNotification: any;

  @ViewChild('content') private content: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fireStore: AngularFirestore,
  ) {
    this.route.queryParamMap.subscribe(snap => {
      this.chatRoom = new ChatRoom(snap['params']);
      //      console.log(this.chatRoom);
      this.getChat();
    });
    this.userType = Globals.providerole ? 'provider' : 'parent';
    var functions = firebase.app().functions('us-central1');
    this.sendNotification = functions.httpsCallable('sendNotification');
  }

  ngOnInit() {
    Globals.chatPage = true;
    Globals.chatBadge = false;
    this.getChat();
  }

  async ionViewDidEnter() {
    this.getChat();
  }

  goBack() {
    this.router.navigate(['tabs/chat-list'], { skipLocationChange: false });
  }

  showDateBreak(i) {
    if (!i) return true;
    var newDate = moment(this.messages[i].timestamp).format("ddd, MMM D YYYY").toString();
    var oldDate = moment(this.messages[i - 1].timestamp).format("ddd, MMM D YYYY").toString();
    return !((oldDate) == (newDate));
  }

  formatDateBreak(ts) {
    if (typeof ts == "object") {
      return (moment(ts.toDate()).format("ddd, MMM D YYYY").toString());
    }
    return (moment(ts).format("ddd, MMM D YYYY").toString());
  }

  sendChat() {
    //console.log(this.chat);
    if (this.chat) {
      var message = new ChatMessage();
      message.userId = this.chatRoom.userId;
      message.message = this.chat;
      message.authorRole = Globals.providerole ? "provider" : "parent";
      message.read = false;
      message.ts = firebase.firestore.FieldValue.serverTimestamp();
      message.timestamp = moment().format("YYYY-MM-DD HH:mm:ss").toString();

      this.fireStore.doc("chats/" + this.chatRoom.providerId).collection("chatRoom/").doc(message.id).set(
        Object.assign({}, message)
      );

      //send notification
      var id = (message.authorRole == "provider") ? this.chatRoom.userId : this.chatRoom.providerId;
      var senderid = (message.authorRole == "provider") ? this.chatRoom.providerId : this.chatRoom.userId;
      this.sendUserNotification(id, this.chat, "Chat:" + senderid, "chat");
    }
    this.chat = "";
  }

  getChat() {
    //console.log('get chat', this.chatRoom);
    var d  = new Date();
    d.setDate(d.getDate() - 14);
    var beginningDateObject = new Date(d);

    this.unsubscribe = this.fireStore.doc("chats/" + this.chatRoom.providerId)
      .collection("chatRoom", ref =>
        ref.where("userId", "==", this.chatRoom.userId)
          .where ("ts", ">", beginningDateObject)
          .orderBy("ts"))
      .valueChanges().subscribe(res => {
        //console.log(res);
        this.messages = res;
        this.loading = false;
        this.markAsRead();
        this.scrollToBottom();
      });
  }

  async markAsRead() {
    var batch = this.fireStore.firestore.batch();
    await this.messages.forEach((chat: ChatMessage) => {
      if (!chat.read && chat.authorRole != this.userType) {
        //update docs in batch ....
        //console.log(chat);
        var chatRef = this.fireStore.doc("chats/" + this.chatRoom.providerId).collection("chatRoom").doc(chat.id).ref;
        batch.update(chatRef, { "read": true });
      }
    });
    batch.commit();
  }

  scrollToBottom() {
    setTimeout(() => {
      this.content.scrollToBottom(300);
    }, 200);
  }

  ionViewWillLeave() {
    Globals.chatPage = false;
  }

  formatDate(message: any) {
    let time = message['timestamp'] ? message['timestamp'] : moment();
    if (typeof time == "object") {
      time = message['timestamp'].toDate();
    }
    return this.formatAMPM(time);
  }

  formatAMPM(date) {
    return moment(date).format("hh:mm A").toString();
  }

  sendUserNotification(user, message, title, type) {
    var userDoc = this.fireStore.doc("users/" + user);
    userDoc.ref.get().then(doc => {
      //console.log(doc.data());
      if (!doc.data()) return;

      var userObj = new User(doc.data());
      var data = {
        user: user,
        message: message,
        title: title,
        deviceTokens: userObj.deviceTokens,
        data: {
          type: type
        }
      }
      //console.log(data);
      this.sendNotification(data).then((result) => {
        //console.log(result);
        result.data.result.results.forEach((result, index) => {
          if (result.error) {
            //console.log("invalid token:"+ userObj.deviceTokens[index]);
            userDoc.set({
              deviceTokens: firebase.firestore.FieldValue.arrayRemove(userObj.deviceTokens[index])
            }, { merge: true });
          }
        });
      }).catch((error) => {
        console.log(error);
      });
    });
  }
}