import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';

import { TabsPage } from './tabs.page';

const routes: Routes = [
  {
    path: 'tabs',
    component: TabsPage,
    children: [
      { path: 'home', loadChildren: () => import('../parent/profile/home.module').then(m => m.HomePageModule) },
      { path: 'list', loadChildren: () => import('../parent/journal/list.module').then(m => m.ListModule) },
      { path: 'providerjournal', loadChildren: () => import('../provider/journal/list.module').then(m => m.ProviderJournalModule) },
      { path: 'reminders', loadChildren: () => import('../provider/reminders/reminder.module').then(m => m.ProviderReminderModule) },
      { path: 'providerhome', loadChildren: () => import('../provider/profile/home.module').then(m => m.ProviderHomePageModule) },
      { path: 'commercial-classroom', loadChildren: () => import('../commercial/classroom/classroom.module').then(m => m.ClassroomPageModule) },
      { path: 'commercial-teacherjournal', loadChildren: () => import('../commercial/journal/list.module').then(m => m.ProviderJournalModule) },
      { path: 'commercial-reminders', loadChildren: () => import('../commercial/reminders/reminder.module').then(m => m.ProviderReminderModule) },
      { path: 'commercial-providerhome', loadChildren: () => import('../commercial/profile/home.module').then(m => m.ProviderHomePageModule) },
      { path: 'commercial-approval', loadChildren: () => import('../commercial/approval/approval.module').then( m => m.ApprovalPageModule)},
      { path: 'teacher-classroom', loadChildren: () => import('../commercial/teacherclassroom/teacherclassroom.module').then( m => m.TeacherclassroomPageModule)},
      { path: 'chat-list', loadChildren: () => import('../chat/chat-list/chat-list.module').then(m => m.ChatListPageModule) },
      { path: 'chat-room', loadChildren: () => import('../chat/chat-room/chat-room.module').then(m => m.ChatRoomPageModule) },
      { path: '', redirectTo: 'tabs/home', pathMatch: 'full' }
    ]
  },
  {
    path: '',
    redirectTo: 'tabs/home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TabsPageRoutingModule { }
