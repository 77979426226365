import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'groupBy',
})

export class GroupByPipe implements PipeTransform {
  transform(value: any, groupByKey: string) {
    if (!value) return null;
    
    const groupedElements: any = {};
    const items: any[] = [];
    
    value.forEach((obj: any) => {
      if (!(obj[groupByKey] in groupedElements)) {
        groupedElements[obj[groupByKey]] = [];
      }
      groupedElements[obj[groupByKey]].push(obj);
    });
    //return groupedElements;
    for (let prop in groupedElements) {
      if (groupedElements.hasOwnProperty(prop)) {
        items.push({
          key: prop,
          list: groupedElements[prop]
        });
      }
    }

    return items;
  }
}