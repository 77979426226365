import { Globals } from '../../../globals';

//ionic - angular
import { Component, OnInit } from '@angular/core';
import { ToastController, LoadingController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { CameraOptions, CameraSource } from '@capacitor/core';
import { Plugins, CameraResultType } from '@capacitor/core';
const { Camera } = Plugins;

//firebase 
import * as firebase from 'firebase/app';

//frameworks
import * as md5 from 'md5';
import { NgxImageCompressService } from 'ngx-image-compress';

//entities
import { Child } from '../../../classes/child.class';


@Component({
  selector: 'child-modal',
  templateUrl: 'child.item.modal.html',
  styleUrls: ['home.page.scss']
})
export class ChildItemModal implements OnInit {

  editItem: any;
  user: string;
  child: Child;
  showUpload: boolean;
  mode: string;
  file: any;

  constructor(private modalController: ModalController,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private imageCompress: NgxImageCompressService,
  ) { }

  ngOnInit() {
    /* 
      If we pass in an 'editItem' property, then we create a copy to store changes to the existing item
      so that the original is not modified unless the user saves.
    */
    //console.log(this.editItem);
    this.child = this.editItem ? Object.assign({}, this.editItem) : new Child()
    //console.log("Edit:" + JSON.stringify(this.child));
  }


  save() {
    var validInput: boolean = true;
    var errMessage: String;
    this.child.email = this.child.email.toLowerCase();

    //validate phone list
    if (this.child.phone1) {
      if (!this.child.phone1.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)) {
        validInput = false;
        errMessage = "Invalid Phone 1. Please enter a valid phone number";
        Globals.presentToast(errMessage);
        return;
      }
      if (!this.child.phone1type) {
        validInput = false;
        errMessage = "Invalid Phone 1 Type. Please select a valid type";
        Globals.presentToast(errMessage);
        return;
      }
    }

    if (this.child.phone2) {
      if (!this.child.phone2.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)) {
        validInput = false;
        errMessage = "Invalid Phone 2. Please enter a valid phone number";
        Globals.presentToast(errMessage);
        return;
      }
      if (!this.child.phone2type) {
        validInput = false;
        errMessage = "Invalid Phone 2 Type. Please select a valid type";
        Globals.presentToast(errMessage);
        return;
      }
    }
    if (this.child.phone3) {
      if (!this.child.phone3.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)) {
        validInput = false;
        errMessage = "Invalid Phone 3. Please enter a valid phone number";
        Globals.presentToast(errMessage);
        return;
      }
      if (!this.child.phone3type) {
        validInput = false;
        errMessage = "Invalid Phone 3 Type. Please select a valid type";
        Globals.presentToast(errMessage);
        return;
      }
    }

    if (!this.child.name) {
      Globals.presentToast("Child Nick name is required. Please enter.");
      return;
    }

    if (!this.child.email) {
      Globals.presentToast("Parent email is required. Please enter.");
      return;
    }

    if (this.child.email == Globals.user.email) {
      Globals.presentToast("Parent email cannot be same as provider's email. Please enter a valid email.");
      return;
    }

    this.child.email = this.child.email.trim();

    this.modalController.dismiss({
      /* 
        We pass back either a newItem or editItem value depending on whether an edit operation is taking place
        so that the list module can decide whether to insert into the items array or splice into it.
      */
      child: this.child
    });
  };

  cancel() {
    this.modalController.dismiss()
  }

  editMode() {
    this.mode = "edit";
  }


  deleteMode() {
    this.modalController.dismiss({ mode: "delete" , child : this.child });
  }


  getMD5(name = "dummy") {
    return (md5(name));
  }


  fileChangeListener($event) {
    this.file = $event.target.files[0];
    if ($event.target.files && $event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        const localUrl = event.target.result;
        this.uploadFileWithCompress(localUrl);
      }
      reader.readAsDataURL($event.target.files[0]);
    }
  }

  uploadFileWithCompress(image) {
    const imgResultBeforeCompress = image;
    //console.log(imgResultBeforeCompress);
    //console.log('Size in bytes was:', this.imageCompress.byteCount(image));

    this.imageCompress.compressFile(image, -1, 10, 10).then(
      result => {
        //console.log('Size in bytes is now:', this.imageCompress.byteCount(result));
        const imageBlob = this.dataURItoBlob(result.split(',')[1]);
        this.uploadFile(imageBlob);
      }
    );
  }

  async uploadFile(image: Blob) {
    //this.presentToast("Uploading ... ");
    var loading = await this.loadingController.create({
      message: 'Uploading file: Please wait...'
    });

    loading.present();

    console.log(this.child.id);
    var storageRef = firebase.storage().ref().child("profile/" + this.child.id + ".jpg");
    var uploadTask = storageRef.put(image);

    uploadTask.on('state_changed', (snapshot) => {
      var progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2);
      loading.message = 'Upload is ' + progress + '% done';
    }, (error) => {
      console.log(error);
      Globals.presentToast("Upload failed :" + error);
      loading.dismiss();
    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        this.child.profile = downloadURL;
        loading.dismiss();
      });
    });
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  setProfilePic() {
    const options: CameraOptions = {
      quality: 70,
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt,
      saveToGallery: false
    }
    Camera.getPhoto(options).then((image) => {
      var imageUrl = image.webPath;
      this.uploadFileWithCompress(imageUrl);
    }, (err) => {
      // Handle error
      console.log("Camera Error:" + err);
    });
  }
}