import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NavController, NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-reactions',
  templateUrl: './reactions.page.html',
  styleUrls: ['./reactions.page.scss'],
  animations: [
    trigger('scaleReactionIcon', [
        state('idle', style({
            transform: 'scale(1)'
        })),
        state('clicking', style({
            transform: 'scale(1.5)'
        })),
        transition('idle <=> clicking', animate('100ms  ease-out')),
    ]),
]
})
export class ReactionsPage  {

  reactionIcons: Object[] = []

    constructor(public navCtrl: NavController, public navParams: NavParams, private popoverCtrl: PopoverController,
                private changeDetector: ChangeDetectorRef) {
        this.reactionIcons = [
            {iconImage: 'assets/img/like.png', name:"like", iconState: 'idle'},
            {iconImage: 'assets/img/love.png', name:"love", iconState: 'idle'},
            {iconImage: 'assets/img/haha.png', name:"haha", iconState: 'idle'},
            {iconImage: 'assets/img/wow.png', name:"wow", iconState: 'idle'},
            {iconImage: 'assets/img/sad.png', name:"sad", iconState: 'idle'},
            {iconImage: 'assets/img/angry.png', name:"angry", iconState: 'idle'}
        ];
    }

    ionViewDidLoad() {
        console.log('ionViewDidLoad ReactionsPage');
    }

    share(icon) {
        icon.iconState = 'clicking';
        this.changeDetector.detectChanges();

        setTimeout(() => {
          icon.iconState = 'idle';
          this.popoverCtrl.dismiss(icon.name);
        }, 300);
    }
  
}
