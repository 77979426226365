import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HomePage } from './home.page';
import { Globals } from '../../../globals';
import { HttpClientModule } from '@angular/common/http';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AuthService } from '../../../login/auth.service';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forChild([{ path: '', component: HomePage }]),
   AngularFirestoreModule,
  ],
  declarations: [
    HomePage,
  ],
  entryComponents: [
   ],
  providers: [
    Globals,
    AuthService
  ]
})
export class HomePageModule {}
