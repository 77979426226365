import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../../../globals';
import { AngularFirestore } from '@angular/fire/firestore';
import { Family } from '../../../classes/child.class';

//frameworks
import * as md5 from 'md5';
import { ChatRoom } from '../../../classes/chat.class';
import { Classroom, Teacher } from '../../../classes/classroom.class';
@Component({
  selector: 'chat-list',
  templateUrl: './chat-list.page.html',
  styleUrls: ['./chat-list.page.scss'],
})
export class ChatListPage implements OnInit {
  chatRoomList: any;
  now: Date = new Date();
  unreadMap = {};
  providerRole : boolean;

  constructor(
    private router: Router,
    private fireStore: AngularFirestore,
  ) {
    
    this.providerRole = Globals.providerole;
  }

  ngOnInit() {
  }

  ionViewWillEnter(){
    if(Globals.providerole==undefined) {
      //wait till initialization if launched through notifications
      setTimeout(() => {
        this.getUsersList();
        this.getUnreadCounts();
      }, 300);
    }
    else {
      this.getUsersList();
      this.getUnreadCounts();
    }
  }

  getUsersList() {
    var id = Globals.user.email;
    //if provider
    if (Globals.providerole) {
      this.fireStore.collectionGroup('families', ref => ref.where("providerId", "==", id))
        .valueChanges().subscribe(res => {
          console.log(res);
          this.chatRoomList = {};
          res.forEach((familyDoc) => {
            var families = new Family(familyDoc);
            var chatRoom = new ChatRoom();
            chatRoom.userType="provider";
            chatRoom.providerId = Globals.user.email;
            console.log(families);

            chatRoom.childList = [];
            for (var id in families.children) {
              chatRoom.userId = families.children[id].email;
              chatRoom.childList.push(families.children[id].name);
            };
            this.chatRoomList[chatRoom.id] = chatRoom;
          })
        });
    } else if(Globals.teacherrole) {
      var checkedInClassroom: Classroom;
      let teacherDoc = this.fireStore.doc("providers/" + Globals.providerId).collection('teachers').doc(id);
      teacherDoc.valueChanges().subscribe(res => {
        let teacher =new Teacher(res); 
        let classroomDoc = this.fireStore.doc("providers/" + Globals.providerId).collection('classroom').doc(teacher.checkedInClassroom);
        classroomDoc.valueChanges().subscribe(res => {
          checkedInClassroom = new Classroom(res);
          this.fireStore.collectionGroup('families', ref => ref.where("providerId", "==", Globals.providerId))
          .valueChanges().subscribe(res => {
            //console.log(res);
            this.chatRoomList = {};
            res.forEach((familyDoc) => {
              var families = new Family(familyDoc);
              var chatRoom = new ChatRoom();
              chatRoom.userType="provider";
              chatRoom.providerId = Globals.providerId;
              //console.log(families);

              chatRoom.childList = [];
              for (var id in families.children) {
                if(checkedInClassroom.students.includes(id)) {              
                  chatRoom.userId = families.children[id].email;
                  chatRoom.childList.push(families.children[id].name);
                }
              };
              if(chatRoom.userId != undefined) {
                this.chatRoomList[chatRoom.id] = chatRoom;
              }
            })
          });
        });
      });      
    } else {
      this.fireStore.doc('families/' + id)
        .valueChanges().subscribe((res) => {
          this.chatRoomList = {};
          //console.log(res);
          var family = new Family(res);
          var chatRoom = new ChatRoom();
          chatRoom.userType="parent";
          chatRoom.providerId = family.providerId;
          chatRoom.userId = Globals.user.email;
          chatRoom.childList = [];

          for (var id in family.children) {
            chatRoom.childList.push(family.children[id].name);
          }
          this.chatRoomList[chatRoom.id] = chatRoom;
          this.openChat(chatRoom.id);
          //console.log(this.chatRoomList);
        });
    }
    //todo: last login time from user collection

  }

  getUnreadCounts() {
    this.unreadMap = {};
    Globals.chatBadge = false;
    this.fireStore.doc("chats/" + Globals.user.email).collection("chatRoom", ref => ref.where("read","!=",true).where("authorRole","==","parent"))
    .valueChanges().subscribe(res => {
      res.forEach( (message) => {
          if(!this.unreadMap[message.userId]) {
              this.unreadMap[message.userId]= {};
          }
          if(this.unreadMap[message.userId].id != message.id) {
            this.unreadMap[message.userId][message.id] = 1;
            Globals.chatBadge = true;
          }
      })
    });
  }

  getunread(userId) {
    var i =0;
    for (let message in this.unreadMap[userId]) {
      i ++;
    };
    return i;
  }

  openChat(roomId: any) {
    //console.log(roomId);
    this.router.navigate(['tabs/chat-room/'], { queryParams:  this.chatRoomList[roomId] , skipLocationChange: false });
  }

  getMD5(name = "dummy") {
    return md5(name);
  }
}
