import { v4 as uuid } from 'uuid';
import * as moment from 'moment';

export class Reminder {
    reminderId: string;
    email:any;
    title:string;
    description :string;
    created : string;
    repeatFreq : any;
    nextDue : string;
    lastCompleted : string;
    repeatFreqDuration : number;
    badge : string;
    badgeColor: string;

    constructor(params){
        this.reminderId = (params.reminderId?this.reminderId : uuid());
        this.email = params.email;
        this.title = params.title;
        this.description = params.description;
        this.created = params.created;
        this.repeatFreq = params.repeatFreq;
        this.repeatFreqDuration = (params.repeatFreqDuration?params.repeatFreqDuration : 1 );
        this.nextDue = params.nextDue;
        this.lastCompleted = params.lastCompleted;
        //this.checkStatus();
    }
}

export class NCReminders {
    reminders : any;

    constructor() {
        this.reminders = {};

        const inspection = {
            id : uuid(),
            title : "Monthly Inspection",
            description : "Monthly inspection for state compliance",
            created : moment().format("YYYY-MM-DD"),
            repeatFreq : "month",
            nextDue : moment().format("YYYY-MM-DD")
        };

        this.reminders[inspection.id] = inspection;

        const fireDrill = {
            id : uuid(),
            title : "Fire Drill",
            description : "Monthly fire drill for state compliance",
            created : moment().format("YYYY-MM-DD"),
            repeatFreq : "month",
            nextDue : moment().format("YYYY-MM-DD")
        };
        this.reminders[fireDrill.id]= fireDrill;

        const CPRTraining = {
            id : uuid(),
            title : "CPR & First Aid Training",
            description : "CPR & First Aid Training certification",
            created : moment().format("YYYY-MM-DD"),
            repeatFreq : "custom",
            nextDue : moment().format("YYYY-MM-DD")
        };
        this.reminders[CPRTraining.id] = CPRTraining;

        const outdoordrill = {
            id : uuid(),
            title : "Outdoor Drill",
            description : "State mandated monthly outdoor drill",
            created : moment().format("YYYY-MM-DD"),
            repeatFreq : "year",
            nextDue : moment().format("YYYY-MM-DD")
        };
        this.reminders[outdoordrill.id] = outdoordrill;

        const ITSSIDSTraining = {
            id : uuid(),
            title : "ITS - SIDS Training",
            description : "Infant / Toddler Safe Sleep and SIDS risk reduction training",
            created : moment().format("YYYY-MM-DD"),
            repeatFreq : "year",
            nextDue : moment().format("YYYY-MM-DD")
        };
        this.reminders[ITSSIDSTraining.id] = ITSSIDSTraining;


        const shelterInPlace = {
            id : uuid(),
            title : "Shelter in place Training",
            description : "Shelter in palce training per local guidlines",
            created : moment().format("YYYY-MM-DD"),
            repeatFreq : "month",
            repeatFreqDuration : 6,
            nextDue : moment().format("YYYY-MM-DD")
        } ;
        this.reminders[shelterInPlace.id] = shelterInPlace;

        const lockdown = {
            id : uuid(),
            title : "Lockdown Training",
            description : "Lockdown training per local guidlines",
            created : moment().format("YYYY-MM-DD"),
            repeatFreq : "month",
            repeatFreqDuration : 6,
            nextDue : moment().format("YYYY-MM-DD")
        };
        this.reminders[lockdown.id] = lockdown;
    }
}