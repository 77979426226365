import { v4 as uuid } from 'uuid';
import * as moment from 'moment';

export class JournalType {

  types: Map<string, string>;
  constructor() {
    //type : icon
    this.types = new Map([
      ["Food", 'restaurant'],
      ["Fluid", 'water'],
      ["Diaper", 'body'],
      ["Sleep Start", 'bed'],
      ["Sleep Awake", 'bed'],
      ["Mood", 'heart'],
      ["Activity", 'baseball'],
      ["Note", 'bookmarks'],
      ["Checkin", 'pin'],
      ["Checkout", 'pin'],
      ["Absence", "remove-circle-outline"],
      ["Supplies", "briefcase-outline"],
      ["Temperature", "thermometer-outline"]
    ]);
  }
}

export class JournalMap {

  journal : Journal[];

  constructor (params=null) {
    if (params) {
      this.journal = params.journal;
    } else {
      this.journal=[];
    }
  }

}

export class Journal {
  id: string;
  childId: string;
  title: string;
  description: string;
  timestamp: Date;
  journalts: any;
  formattedJournalts: string;
  formattedJournalDate: string;
  formattedJournalTime: string;

  type: any;
  file: string;
  profile: string;
  macro: string;
  parentId: any;

  index : number;
  fileList: any[];
  videofile: any;
  reminder: boolean;
  approval: boolean;

  constructor(params) {

    if (!params || !params.id) {
      this.id = uuid();
      this.fileList = [];
      this.approval = true;
    } else {
      this.id = params.id;
      this.childId = params.childId;
      this.parentId = params.parentId;
      this.title = params.title;
      this.description = params.description;
      this.type = params.type;
      if (params.file) this.file = params.file;
      if (params.fileList) this.fileList = params.fileList;
      if (params.videofile) this.videofile = params.videofile;
      if (params.macro) this.macro = params.macro;
      if (params.reminder) this.reminder = params.reminder;
      if (params.approval) this.approval = params.approval;
    }
    if (params.journalts) {
      //use existing timestamps
      this.journalts = params.journalts;
      this.formattedJournalDate = params.formattedJournalDate;
      this.formattedJournalts = params.formattedJournalts;
      this.formattedJournalTime = params.formattedJournalTime;
    } else {
      //create new formatted timestamps
      this.generateTimestamps(new Date());
    }
  }

  generateTimestamps(today) {
    this.timestamp = today;
    var m  = moment(this.timestamp);
    this.formattedJournalts = m.format("ddd, MMM D YYYY, h:mm a").toString();
    this.formattedJournalDate = m.format("ddd, MMM D YYYY").toString()
    this.formattedJournalTime = m.format("YYYY-MM-DDTHH:mm:ssZZ").toString();
    this.journalts = m.format("YYYYMMDDHHmmSSSS").toString();
  }
}