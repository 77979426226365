import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})

export class Globals {

  static user: any;
  static token: any;
  static providerole: boolean;
  static toastController: ToastController;  
  static signedIn: any;
  static chatPage: boolean;
  static chatBadge : boolean;
  static commercial: boolean;
  static teacherrole: boolean;
  static providerId: string;

  constructor() {
  }

  static async presentToast(text, duration = 2000) {
    if (!Globals.toastController) Globals.toastController = new ToastController();
    const toast = await Globals.toastController.create({
      message: text,
      position: 'top',
      duration: duration
    });
    toast.present();
  }
}



