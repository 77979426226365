import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AuthGuardService } from '../../services/auth-route-guard'


import { Globals } from '../../globals';
import { TabsPage } from './tabs.page';
import { TabsPageRoutingModule } from './tabs.router.module';

import { ListModule } from '../parent/journal/list.module';
import { HomePageModule } from '../parent/profile/home.module';

import { ProviderHomePageModule } from '../provider/profile/home.module';
import { ProviderJournalModule } from '../provider/journal/list.module';
import { ProviderReminderModule } from '../provider/reminders/reminder.module';
import { AngularFirestoreModule } from '@angular/fire/firestore';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    TabsPageRoutingModule,
    HomePageModule,
    ListModule,
    ProviderHomePageModule,
    ProviderJournalModule,
    ProviderReminderModule,
    AngularFirestoreModule
  ],
  declarations: [
    TabsPage,
  ],
  providers: [
    AuthGuardService,  
    Globals
  ]
})
export class TabsPageModule {}
