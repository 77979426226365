import { Pipe, PipeTransform } from '@angular/core';

import { Journal } from '../../../classes/journal.class';
import { Filters } from '../../../classes/filter.class';
import * as moment from 'moment';

@Pipe({
  name: 'journalfilter',
  pure: false
})
export class JournalFilterPipe implements PipeTransform {
  transform(items: Journal[], filter: Filters): Journal[] {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be kept, false will be filtered out
    return items.filter((item: Journal) => this.applyFilter(item, filter));
  }

  /**
   * Perform the filtering.
   * 
   * @param {Journal} Journal The book to compare to the filter.
   * @param {any} filter The filter to apply.
   * @return {boolean} True if book satisfies filters, false if not.
   */
  applyFilter(journalItem: Journal, filter: Filters): boolean {

    if (!filter.filtersApplied) return true;
    var type = journalItem.type.toLowerCase();
    if (type.includes("sleep")) {
      type = "sleep";
    }
    
    return(filter.categoryFilter[type]); 
  }
}