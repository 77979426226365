export class Filters {

  filtersApplied: boolean;

  timeFilter: string;

  categoryFilter: {};

  constructor() {
    this.filtersApplied = false;

    this.categoryFilter = {
      "food": true,
      "fluid": true,
      "diaper": true,
      "sleep": true,
      "activity": true,
      "mood": true,
      "checkin": true,
      "checkout": true,
      "note": true,
      "supplies": true,
      "absence":true,
    };
  }

};